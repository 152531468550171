<template>
    <div class="comp_Mainmenu">
        <v-app-bar
        absolute dense dark clipped-left
        color="primary"
        elevation="5"
        class="pr-4"
        >
            <v-app-bar-nav-icon
            @click="showNavigation= !showNavigation"
            ></v-app-bar-nav-icon>

            <v-tabs
              dark show-arrows hide-slider
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab
                v-for="link in quicknavLinks"
                :key="link.title"
                :to="link.route"
                :class="{
                  'v-tab--active': link.route === `/${$store.state.main.routerCurrentFeature}`
                }"
              >
                <v-icon :left="quicknavLinks.length === 1 && $vuetify.breakpoint.mdAndUp">
                  {{ link.icon }}
                </v-icon>
                <span v-if="quicknavLinks.length === 1 && $vuetify.breakpoint.mdAndUp">
                  {{ link.title }}
                </span>
              </v-tab>
            </v-tabs>
        </v-app-bar>

        <v-navigation-drawer
        absolute temporary clipped
        v-model="showNavigation"
        >
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title class="text-h6">
                    {{ $store.state.main.save.settings.app_titel }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <!-- {{ $store.state.main.save.settings.app_untertitel }} -->
                    {{ $store.state.main.save.user.ident }}
                </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="link in sidenavLinks"
                    :key="link.title"
                    link
                    :to="link.route"
                    :class="{
                      'v-tab--active v-list-item--active': link.route ===
                      `/${$store.state.main.routerCurrentFeature}`
                    }"
                    >
                <v-list-item-icon>
                    <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ link.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item
                  link
                  @click="updateApp();"
                  v-if="$store.state.main.save.app_api_update"
                  >
                <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                      App Update
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item> -->

                <v-list-item
                  link
                  @click="$store.dispatch('login/tryLogout', {
                    router: $router,
                  })"
                  >
                <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Ausloggen</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>

export default {
  data: () => ({
    showNavigation: false,
    quicknavLinks: [],
    sidenavLinks: [],
  }),
  props: {
    maxquicknav: {
      type: Number,
      default: 2,
    },
  },
  computed: {
  },
  watch: {
    maxquicknav() {
      this.updateQuicknav();
    },
  },
  methods: {
    updateApp() {
      console.log('asd');
      this.$router.go();
    },
    updateQuicknav() {
      const links = this.$store.state.main.save.mainmenu_links;
      const quicknavLinks = links.filter((o) => parseInt(o.quicknav, 10) === 1);
      let sidenavLinks = links.filter((o) => parseInt(o.quicknav, 10) === 0);
      const { maxquicknav } = this;
      // die zu vielen Links aus dem Quicknav entfernen
      let i = 0;
      let splice = [];
      quicknavLinks.forEach(() => {
        if (i >= maxquicknav) {
          splice = quicknavLinks.slice(i, quicknavLinks.length);
          quicknavLinks.splice(i, quicknavLinks.length - i);
        }
        i += 1;
      });
      // die zu vielen Links in die sidebar packen
      sidenavLinks = sidenavLinks.concat(splice);
      // Menüs sortieren und speichern:
      sidenavLinks.sort((a, b) => {
        i = 0;
        if (parseInt(a.sort, 10) > parseInt(b.sort, 10)) {
          i = 1;
        } else if (parseInt(b.sort, 10) > parseInt(a.sort, 10)) {
          i = -1;
        }
        return i;
      });
      this.sidenavLinks = sidenavLinks;
      quicknavLinks.sort((a, b) => {
        i = 0;
        if (parseInt(a.sort, 10) > parseInt(b.sort, 10)) {
          i = 1;
        } else if (parseInt(b.sort, 10) > parseInt(a.sort, 10)) {
          i = -1;
        }
        return i;
      });
      this.quicknavLinks = quicknavLinks;
    },
  },
  created() {
    this.updateQuicknav();
  },
};
</script>

<style scoped>
  .v-tab--active,
  .v-list-item--active {
    pointer-events:none;
  }
</style>
