<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <EditAuftrag
        v-if="editAuftrag.id"
        :prop_kunden="JSON.parse(JSON.stringify(prop_kunden))"
        :prop_settings="JSON.parse(JSON.stringify(prop_settings))"
        :prop_kosten="JSON.parse(JSON.stringify(prop_kosten))"
        :prop_auftrag="editAuftrag"
        :prop_isSaving_editAuftrag="isSaving_editAuftrag"
        :prop_isSaving_delAuftrag="isSaving_delAuftrag"
        @close="editAuftrag = {}"
        @save="saveAuftrag($event);"
        @del="delAuftrag($event);"
      />
      <v-row>
        <v-col>
          <v-btn
            small color="primary"
            @click="addAuftrag(prop_addauftragKundennummer);"
            :loading="isSaving_addAuftrag"
            class="me-2"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
          <AuftraegeUebersicht
            :prop_auftraege="prop_auftraege"
            :prop_kosten="prop_kosten"
            :prop_settings="prop_settings"
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="4" class="py-0" align="center">
          <!-- <v-checkbox
            v-model="nurvertraegeanzeigen"
            label="Nur Verträge"
          />
          <v-checkbox
            v-model="vertraegeanzeigen"
            label="Verträge anzeigen"
          /> -->
          <span v-if="vertraegeanzeigen">Verträge</span>
          <span v-if="!vertraegeanzeigen">Aufträge</span>
          <v-btn
            class="ml-2"
            x-small
            @click="vertraegeanzeigen = !vertraegeanzeigen"
          >
            <!-- <span v-if="!vertraegeanzeigen">Verträge</span>
            <span v-if="vertraegeanzeigen">Aufträge</span> -->
            <v-icon small>mdi-repeat</v-icon>
          </v-btn>
        </v-col>
        <v-col class="py-0">
          <v-checkbox
            v-model="nuroffeneanzeigen"
            label="Nur offene Aufträge anzeigen"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            clearable dense
            label="Suchen..."
            append-icon="mdi-magnify"
            v-model="search"
            :rules="[
              $store.state.main.inputValidationRules.name,
            ]"
            class="ma-0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="
          save.auftraege.filter((o) => {
            if (
              (
                (o.typ === 'Auftrag' && o.status <= 5)
                || (o.typ === 'Auftrag' && o.status == 6) // Offene Zahlung
                || (o.typ === 'Vertrag' && o.status <= 5)
                || (o.typ === 'Vertrag' && o.status == 6) // Offene Zahlung
                || !nuroffeneanzeigen
              ) && (
                !nurvertraegeanzeigen
                || o.typ === 'Vertrag'
              ) && (
                (
                  vertraegeanzeigen
                  && o.typ === 'Vertrag'
                )
                || (
                  !vertraegeanzeigen
                  && o.typ === 'Auftrag'
                )
              )
            ) {
              return o;
            }
          }).sort((a, b) => {
            if (a.time_lastUpdate > b.time_lastUpdate) {
              return -1;
            } else if (a.time_lastUpdate < b.time_lastUpdate) {
              return 1;
            }
            if (a.id > b.id) {
              return 1;
            } else if (a.id < b.id) {
              return -1;
            }
            return 0;
          })
        "
        :search="search"
        :loading="loading"
        :items-per-page="50"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Aufträge gefunden"
        no-results-text="Keine Aufträge gefunden"
        loading-text="Lade Daten... Bitte warten"
        mobile-breakpoint="850"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item.titel="{ item }">
          <div
            class="primary--text"
            @click="editAuftrag = item"
            style="cursor:pointer;"
          >
            <b
              v-if="item.titel !== '' && item.typ === 'Vertrag'"
              :class="{
                'success--text': item.status <= 5,
                'error--text': item.status == 6,
                'warning--text': item.status == 7,
                'grey--text': item.status >= 8,
              }"
            >
              {{ item.titel }}
            </b>
            <b
              v-if="item.titel !== '' && item.typ === 'Auftrag'"
              :class="{
                'success--text': item.status <= 5,
                'error--text': item.status == 6,
                'grey--text': item.status >= 8,
              }"
            >
              {{ item.titel }}
            </b>
            <b v-if="item.titel === ''">
              --
            </b>
            <v-dialog
              v-if="
                item.leistungen.filter((o) => o.kosten > 0 && o.id_kosten <= 0).length > 0
              "
              v-model="dialog_infoKeineEchtenKosten[item.id]"
              width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  x-small color="warning"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                >!</v-chip>
              </template>
              <!-- <v-card>
                <v-card-title>asd</v-card-title>
                <v-card-text>asd</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    small text
                  >schließen</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    small text color="primary"
                  >OK</v-btn>
                </v-card-actions>
              </v-card> -->
              <v-card>
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="ma-0"
                >
                  Es gibt noch Leistungen mit Kosten,
                  die nicht mit einer Kostenposition verknüpft sind.
                  <v-divider class="my-2"></v-divider>
                  <div align="right"><v-btn
                    small text
                    @click="dialog_infoKeineEchtenKosten[item.id] = false"
                  >OK</v-btn></div>
                </v-alert>
              </v-card>
            </v-dialog>
            <v-dialog
              v-if="
                item.offeneRechnungen.betrag > 0
              "
              v-model="dialog_infoRechnungOffen[item.id]"
              width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  x-small color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1 py-1 px-2"
                ><v-icon small left>mdi-cash</v-icon>{{
                  item.offeneRechnungen.betrag
                    .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                }}</v-chip>
              </template>
              <v-card>
                <v-alert
                  border="bottom"
                  colored-border
                  type="error"
                  elevation="2"
                  class="ma-0"
                >
                  Es gibt noch offene Rechnungsbeträge
                  ({{ item.offeneRechnungen.betrag
                    .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}),
                  für die kein Buchungsbeleg hochgeladen wurde.
                  <v-divider class="my-2"></v-divider>
                  <div align="right"><v-btn
                    small text
                    @click="dialog_infoRechnungOffen[item.id] = false"
                  >OK</v-btn></div>
                </v-alert>
              </v-card>
            </v-dialog>
          </div>
          <div style="font-size:70%;" class="grey--text"
            v-if="item.time_lastUpdate > 0"
          >
            {{
              new Date(item.time_lastUpdate * 1000).toLocaleString('de-DE', {
                year: 'numeric', month: '2-digit', day: '2-digit',
                hour: 'numeric', minute: 'numeric',
              })
            }} Uhr
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <span
            v-if="
              item.typ === 'Auftrag'
              && prop_settings.auftragsstatus.filter((o) => o.nummer === item.status).length > 0
            "
          >
            <v-icon
              :class="{
                'success--text': item.status <= 5,
                'error--text': item.status == 6,
                'grey--text': item.status >= 8,
              }"
            >
              {{ prop_settings.auftragsstatus.filter((o) => o.nummer === item.status)[0].icon }}
            </v-icon><br>
          </span>
          <span
            v-if="
              item.typ === 'Vertrag'
              && prop_settings.vertragsstatus.filter((o) => o.nummer === item.status).length > 0
            "
          >
            <v-icon
              :class="{
                'success--text': item.status <= 5,
                'error--text': item.status == 6,
                'warning--text': item.status == 7,
                'grey--text': item.status >= 8,
              }"
            >
              {{ prop_settings.vertragsstatus.filter((o) => o.nummer === item.status)[0].icon }}
            </v-icon><br>
          </span>
        </template>
        <template v-slot:item.umsatz="{ item }">
          <div align="left">
            {{
              item.umsatz.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
            }}
          </div>
        </template>
        <template v-slot:item.kosten="{ item }">
          <div align="left">
            {{
              item.kosten.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
            }}
          </div>
        </template>
        <template v-slot:item.gewinn="{ item }">
          <div align="left">
            {{
              item.gewinn.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
            }}
          </div>
        </template>
        <template v-slot:item.vertrag_zahlungJaehrlich="{ item }">
          <div align="left"
            v-if="item.typ === 'Vertrag'"
          >
            <span v-if="item.vertrag_zahlungJaehrlich === '1'">jährlich</span>
            <span v-if="item.vertrag_zahlungJaehrlich === '0'">monatlich</span>
          </div>
        </template>
        <template v-slot:item.vertrag_ende="{ item }">
          <div align="left"
            v-if="item.typ === 'Vertrag'"
          >{{ item.dateVertrag_ende }}</div>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import EditAuftrag from '@/components/Funktionen/400/410-Kunden/EditAuftrag.vue';
import AuftraegeUebersicht from '@/components/Funktionen/400/410-Kunden/AuftraegeUebersicht.vue';

export default {
  components: {
    EditAuftrag,
    AuftraegeUebersicht,
  },
  props: {
    prop_addauftragKundennummer: {
      type: String,
      default: '0',
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_auftraege: {
      type: Array,
      default() { return []; },
    },
    prop_kunden: {
      type: Array,
      default() { return []; },
    },
    prop_kosten: {
      type: Array,
      default() { return []; },
    },
    prop_nuroffeneanzeigen: {
      type: Boolean,
      default: true,
    },
    prop_nurvertraegeanzeigen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog_infoKeineEchtenKosten: {},
    dialog_infoRechnungOffen: {},
    nuroffeneanzeigen: true,
    nurvertraegeanzeigen: false,
    vertraegeanzeigen: false,
    editAuftrag: {},
    xLoaded: {
      auftraege: {},
    },
    xChanged: {},
    save: {
      settings: {},
      auftraege: [],
    },
    isSaving: false,
    isSaving_editAuftrag: false,
    isSaving_delAuftrag: false,
    isSaving_addAuftrag: false,
    loading: false,
    search: '',
    headers: [
      // { text: 'Auftrag', value: 'auftragsnummer', width: '150px' },
      { text: 'Status', value: 'status' },
      { text: 'Titel', value: 'titel', width: '200px' },
      { text: 'Umsatz', value: 'umsatz' },
      { text: 'Kosten', value: 'kosten' },
      { text: 'Gewinn', value: 'gewinn' },
      { text: 'Vertrag', value: 'vertrag_zahlungJaehrlich' },
      { text: 'Verlängerung', value: 'vertrag_ende' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
  },
  watch: {
    prop_settings: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_auftraege: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
  },
  methods: {
    addAuftrag(kundennummer) {
      this.isSaving_addAuftrag = true;
      this.$emit('tryGetOrSave', { type: 'addAuftrag', data: { kundennummer } });
    },
    saveAuftrag(data) {
      this.isSaving_editAuftrag = true;
      this.$emit('tryGetOrSave', { type: 'saveAuftrag', data });
    },
    delAuftrag(data) {
      this.isSaving_delAuftrag = true;
      this.$emit('tryGetOrSave', { type: 'delAuftrag', data });
    },
    datenLaden() {
      /*
        Wenn neue Daten übergeben wurden,
        prüfe ob diese sich von den zuletzt geladenen unterscheiden.
        Wenn ja, überschreibe die aktuellen Daten mit den neu übergebenen Daten.
      */
      let anythingUpdated = false;
      if (JSON.stringify(this.prop_settings) !== JSON.stringify(this.xLoaded.settings)) {
        this.save.settings = JSON.parse(JSON.stringify(this.prop_settings));
        this.xLoaded.settings = JSON.parse(JSON.stringify(this.prop_settings));
        anythingUpdated = true;
      }
      this.prop_auftraege.forEach((k) => {
        if (!this.save.auftraege.filter((o) => o.id === k.id).length > 0) {
          this.save.auftraege.push(k);
          this.xLoaded.auftraege[k.id] = JSON.stringify(k);
          anythingUpdated = true;
        } else if (
          JSON.stringify(k) !== this.xLoaded.auftraege[k.id]
        ) {
          this.save.auftraege.splice(
            this.save.auftraege.indexOf(this.save.auftraege.filter((o) => o.id === k.id)[0]),
            1,
          );
          this.save.auftraege.push(k);
          this.xLoaded.auftraege[k.id] = JSON.stringify(k);
          anythingUpdated = true;
        }
      });
      this.save.auftraege.forEach((k) => {
        if (!this.prop_auftraege.filter((o) => o.id === k.id).length > 0) {
          this.save.auftraege.splice(
            this.save.auftraege.indexOf(this.save.auftraege.filter((o) => o.id === k.id)[0]),
            1,
          );
          anythingUpdated = true;
        }
      });
      if (anythingUpdated) {
        this.isSaving = false;
        this.isSaving_addAuftrag = false;
        if (this.save.settings.lastAddedAuftrag) {
          if (
            this.save.auftraege
              .filter((o) => o.id === this.save.settings.lastAddedAuftrag).length > 0
          ) {
            [this.editAuftrag] = this.save.auftraege
              .filter((o) => o.id === this.save.settings.lastAddedAuftrag);
          }
        }
        this.isSaving_editAuftrag = false;
        if (this.save.settings.lastEditedAuftrag === this.editAuftrag.id) {
          // this.editAuftrag = {};
          if (this.prop_settings.lastEditedAuftrag) {
            this.prop_settings.lastEditedLeistungenNeuKosten.forEach((k) => {
              if (this.editAuftrag.leistungen.filter((o) => o.pos === k.pos).length > 0) {
                this.editAuftrag.leistungen
                  .filter((o) => o.pos === k.pos)[0].id_kosten = k.id_kosten;
                this.editAuftrag.leistungen
                  .filter((o) => o.pos === k.pos)[0].tabs_neuKosten = 0;
                this.editAuftrag.leistungen
                  .filter((o) => o.pos === k.pos)[0].betrag = k.betrag;
              }
            });
            //  .filter((o) => o.id === this.prop_settings.lastEditedAuftrag));
          }
          this.prop_settings.lastEditedAuftrag = 0;
        }
        this.isSaving_delAuftrag = false;
        if (this.save.settings.lastDeletedAuftrag) {
          this.editAuftrag = {};
        }
        this.save.auftraege.sort((a, b) => {
          if (a.auftragsnummer > b.auftragsnummer) {
            return -1;
          }
          if (a.auftragsnummer < b.auftragsnummer) {
            return 1;
          }
          return 0;
        });
        this.save.auftraege.sort((a, b) => {
          if (a.status > b.status) {
            return 1;
          }
          if (a.status < b.status) {
            return -1;
          }
          return 0;
        });
      }
    },
  },
  created() {
    this.nuroffeneanzeigen = this.prop_nuroffeneanzeigen;
    this.nurvertraegeanzeigen = this.prop_nurvertraegeanzeigen;
    this.datenLaden();
    if (this.$router.history.current.query.a) {
      if (this.prop_auftraege
        .filter((a) => a.id === this.$router.history.current.query.a).length > 0) {
        [this.editAuftrag] = this.prop_auftraege
          .filter((a) => a.id === this.$router.history.current.query.a);
        this.$router.push(this.$router.history.current.path);
      }
    }
  },
};
</script>
