<template>
  <div class="view view_Hydranten" v-if="doRender">
    <viewTabsTop v-if="tabs.length > 0" :tabs="tabs" />
    <!-- CONTENT -->
    <template
      v-if="
        // (currentTab.code === '' || currentTab.code === '000')
        (currentTab.code === '' || currentTab.code === '000' || currentTab.code === '030')
        && tabs.filter((o) => o.erlaubt === 'x|310|000').length > 0
      "
    >
      <Karte
        :data="data"
        :idHydrant="currentTab.query.h"
        :propStatus_setLocation="currentTab.query.setLocation === '1'"
        :location="{ lat: currentTab.query.lat, lon: currentTab.query.lon }"
        @reload="tryGetHydranten();"
      />
      <!-- <Hydrant
        v-else-if="
          currentTab.code === '030'
          && tabs.filter((o) => o.erlaubt === 'x|310|000').length > 0
        "
        v-model="data"
        :idHydrant="currentTab.query.h"
        :location="{ lat: currentTab.query.lat, lon: currentTab.query.lon }"
        :tab="currentTab.query.t"
        @reload="tryGetHydranten();"
      /> -->
      <v-dialog
        v-if="
          (currentTab.code === '030')
          && tabs.filter((o) => o.erlaubt === 'x|310|000').length > 0
        "
        v-model="dialog_editHydrant"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="$router.push({ path: '/310' });"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Settings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            </v-toolbar-items>
          </v-toolbar>
          <Hydrant
            v-if="
              currentTab.code === '030'
              && tabs.filter((o) => o.erlaubt === 'x|310|000').length > 0
            "
            v-model="data"
            :idHydrant="currentTab.query.h"
            :location="{ lat: currentTab.query.lat, lon: currentTab.query.lon }"
            :tab="currentTab.query.t"
            @reload="tryGetHydranten();"
          />
        </v-card>
      </v-dialog>
    </template>
    <Routen
      v-else-if="
        currentTab.code === '010'
        && tabs.filter((o) => o.erlaubt === 'x|310|000').length > 0
      "
    />
    <Suchen
      v-else-if="
        currentTab.code === '020'
        && tabs.filter((o) => o.erlaubt === 'x|310|000').length > 0
      "
    />
    <Einstellungen
      v-else-if="
        currentTab.code === '100'
        && tabs.filter((o) => o.erlaubt === 'x|310|010').length > 0
      "
      :data="data"
      @reload="tryGetHydranten();"
    />
    <p
      v-else
    >
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 0" :tabs="tabs" />
  </div>
</template>

<script>
import $ from 'jquery';
import { latLng, icon } from 'leaflet';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';

import Karte from '@/components/Funktionen/300/Hydrantenpflege/Karte/Karte.vue';
import Routen from '@/components/Funktionen/300/Hydrantenpflege/Routen/Routen.vue';
import Suchen from '@/components/Funktionen/300/Hydrantenpflege/Suchen/Suchen.vue';
import Hydrant from '@/components/Funktionen/300/Hydrantenpflege/Hydrant/Hydrant.vue';
import Einstellungen from '@/components/Funktionen/300/Hydrantenpflege/Einstellungen/Einstellungen.vue';

export default ({
  name: 'Hydranten',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Karte,
    Routen,
    Suchen,
    Hydrant,
    Einstellungen,
  },
  data: () => ({
    dialog_editHydrant: true,
    data: {
      updateCount_editHydrant: 0,
      settings: null,
      hydranten: [],
      strassen: null,
      statuswerte: null,
      maengel: null,
    },
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-map-search',
        title: 'Karte',
        route: '/310',
        erlaubt: 'x|310|000',
      },
      {
        icon: 'mdi-map-marker-path',
        title: 'Routen',
        route: '/310-010',
        erlaubt: 'x|310|000',
      },
      {
        icon: 'mdi-magnify',
        title: 'suchen',
        route: '/310-020',
        erlaubt: 'x|310|000',
      },
      {
        icon: 'mdi-cogs',
        title: 'Einstellungen',
        route: '/310-100',
        erlaubt: 'x|310|010',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  methods: {
    async tryGetHydranten() {
      this.data.hydranten = [];
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=getHydranten`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.data.settings = response.settings;
            this.data.hydranten = [];
            response.hydranten.forEach((hyd) => {
              this.data.hydranten.push({
                id: hyd.id,
                coords_lat: hyd.coords_lat,
                coords_lon: hyd.coords_lon,
                coords: latLng(parseFloat(hyd.coords_lat), parseFloat(hyd.coords_lon)),
                icon: icon({
                  iconUrl: `img/300/Hydrantenpflege/mapMarkers/hydrant_status_${hyd.status}.png`,
                  iconSize: [20, 20],
                  iconAnchor: [10, 20],
                }),
                idStrasse: hyd.id_strasse,
                hausnummer: hyd.hausnummer,
                lageSchild: hyd.lageSchild,
                nummer: hyd.nummer,
                lage: hyd.lage,
                durchmesser: hyd.durchmesser,
                info: hyd.info,
                status: hyd.status,
                mangel: hyd.mangel,
                lastUpdate: hyd.lastUpdate,
              });
            });
            if (this.data.hydranten.length === 0) {
              this.data.hydranten.push({
                id: 0,
                coords_lat: 0,
                coords_lon: 0,
                coords: latLng(parseFloat(0), parseFloat(0)),
                icon: icon({
                  iconUrl: '',
                  iconSize: [0, 0],
                  iconAnchor: [0, 0],
                }),
                idStrasse: 0,
                hausnummer: 0,
                lageSchild: '',
                nummer: '',
                lage: '',
                durchmesser: '',
                info: '',
                status: '',
                mangel: '',
              });
            }
            this.data.strassen = response.strassen;
            this.data.statuswerte = response.statuswerte;
            this.data.maengel = response.maengel;
          }
        },
        timeout: 10000,
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Hydranten konnten nicht geladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
    this.tryGetHydranten();
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neue Tab innerhalb dieser Seite aufgerufen wird
    this.data.updateCount_editHydrant += 1;
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
