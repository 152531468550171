<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <div class="pa-4">
        <p>
          Gibt es einen Nutzerparameter für beurlaubte Nutzer?
          Beurlaubte Nutzer werden bei neuen Abfragen ignoriert.
        </p>
        <v-select
          label="Nutzerparameter Beurlaubt"
          :items="$store.state.main.save.nutzerparameter
            .filter((o) => o.typ === 9 && o.selectoptions.length > 0)"
          item-value="id"
          item-text="name"
          v-model="store.settings.id_nutzerparameter_beurlaubt"
          no-data-text="Keine passenden Nutzerparameter gefunden"
        />
        <v-select
          v-if="
            $store.state.main.save.nutzerparameter
            .filter((o) => o.id === store.settings.id_nutzerparameter_beurlaubt).length > 0
          "
          label="Option für Beurlaubt"
          :items="$store.state.main.save.nutzerparameter
            .filter((o) => o.id === store.settings.id_nutzerparameter_beurlaubt)[0].selectoptions"
          item-value="id"
          item-text="wert"
          v-model="store.settings.id_nutzerparameter_beurlaubt_selectoptionTrue"
        />
        <p>
          Welchen Status sollen alle Prüflinge zu Beginn einer neuen Abfrage erhalten?
        </p>
        <v-select
          label="Standard Status"
          :items="moeglicheStatus"
          item-value="nummer"
          item-text="name"
          v-model="store.settings.standard_status"
        />
        <p>
          Welchen Status sollen alle Prüflinge zu Beginn einer
          per QR-Code automatisch erstellten Abfrage erhalten?
          <br />(Der scannende Nutzer erhält den Status "Anwesend")
        </p>
        <v-select
          label="Standard Status bei QR Codes"
          :items="moeglicheStatus.filter((o) => o.nummer !== '1')"
          item-value="nummer"
          item-text="name"
          v-model="store.settings.standard_status_qrscanned"
        />
        <p>
          Welche Rechte und Bedingungen sollen für neue Slots standardmäßig gelten?
        </p>
        <Bedingung
          v-model="store.settings.standardbedingungen.f150.pruefling"
          text_titel="Standard Prüflinge"
          :text_beschreibung="`Welche Nutzer sollen Standardmäßig in den
          Abfragen betrachtet werden?`"
          text_alle="Alle Nutzer prüfen"
          text_gruppen="Nur bestimmte Nutzer prüfen"
        />
        <Bedingung
          v-model="store.settings.standardbedingungen.f150.sehen"
          text_titel="Standard Betrachter"
          :text_beschreibung="`Welche Nutzer sollen Standardmäßig
          Abfragen sowie Übersichten sehen dürfen?`"
          text_alle="Alle Nutzer sind Betrachter"
          text_gruppen="Nur bestimmte Nutzer sind Betrachter"
        />
        <Bedingung
          v-model="store.settings.standardbedingungen.f150.anwhinzufuegen"
          text_titel="Standard Redakteure"
          :text_beschreibung="`Welche Nutzer sollen Standardmäßig Abfragen für
          Slots hinzufügen dürfen?`"
          text_alle="Alle Nutzer sind Redakteure"
          text_gruppen="Nur bestimmte Nutzer sind Redakteure"
        />
        <Bedingung
          v-model="store.settings.standardbedingungen.f150.anwbearbeiten"
          text_titel="Standard Abfragenverwalter"
          :text_beschreibung="`Welche Nutzer sollen Standardmäßig die Abfragen
          von Slots bearbeiten und löschen dürfen?`"
          text_alle="Alle Nutzer sind Abfragenverwalter"
          text_gruppen="Nur bestimmte Nutzer sind Abfragenverwalter"
        />
        <Bedingung
          v-model="store.settings.standardbedingungen.f150.bearbeiten"
          text_titel="Standard Slotadministrator"
          :text_beschreibung="`Welche Nutzer sollen Slots und die
          Abfragen Standardmäßig bearbeiten sowie neue hinzufügen dürfen?`"
          text_alle="Alle Nutzer sind Slotadministrator"
          text_gruppen="Nur bestimmte Nutzer sind Slotadministrator"
        />
        <div align="center">
          <span style="display:none;">{{ changedStore }}</span>
          <v-btn
            small
            :disabled="!changedStore.settings"
            :loading="$store.state.f150.saving.settings"
            color="success"
            @click="$store.dispatch(`f${sitecode}/tryGetOrSaveSettings`, { dataToSave: {
              type: 'settings',
              data: store.settings,
            }});"
          >
            <v-icon left>mdi-account</v-icon>
            Änderungen speichern
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import Bedingung from '@/components/Inputs/Bedingung.vue';

export default {
  components: {
    Bedingung,
  },
  props: {
    sitecode: {
      type: String,
      default: '150',
    },
  },
  data: () => ({
    store: {},
    lastLoadedStore: {},
    changedStore: {
      v: 0,
    },
    moeglicheStatus: [
      {
        nummer: '0',
        name: 'Ohne Status',
        text: 'ist ohne Status',
        farbe: 'grey lighten-3',
        style: { textDecoration: 'line-through' },
      },
      {
        nummer: '1',
        name: 'Anwesend',
        text: 'ist anwesend',
        farbe: 'success',
        style: {},
      },
      {
        nummer: '2',
        name: 'Entschuldigt',
        text: 'fehlt entschuldigt',
        farbe: 'warning',
        style: {},
      },
      {
        nummer: '3',
        name: 'Abwesend',
        text: 'fehlt unentschuldigt',
        farbe: 'error',
        style: {},
      },
    ],
  }),
  computed: {
    loadedStore: { // Databinding mit vuex state
      get() {
        return this.$store.state[`f${this.sitecode}`].store;
      },
    },
  },
  watch: {
    loadedStore: {
      handler(neu) {
        /*
          Wenn settings in der Datenbank geändert wurde,
          lade diese neu ein.
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.store.settings = neu.settings;
          this.lastLoadedStore.settings = JSON.stringify(neu.settings);
          this.changedStore.settings = false;
        }
      },
      deep: true,
    },
    store: {
      handler(neu) {
        /*
          Prüfe ob settings lokal geändert wurde
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.changedStore.settings = true;
        } else {
          this.changedStore.settings = false;
        }
        this.changedStore.v += 1;
      },
      deep: true,
    },
  },
  methods: {
  },
  created() {
    // console.log(this.$vuetify);
    // this.$vuetify.theme.isDark = true;
    // this.$vuetify.theme.themes.light.primary = '#71b036';
    this.store = JSON.parse(JSON.stringify(this.$store.state[`f${this.sitecode}`].store));
    this.lastLoadedStore.settings = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.settings);
    this.changedStore.settings = false;
  },
};
</script>
