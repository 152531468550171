<template>
    <div
      :class="{
        'component_karte component_karte_desktop': $vuetify.breakpoint.lgAndUp,
        'component_karte component_karte_mobile': $vuetify.breakpoint.mdAndDown
      }"
    >
        <v-progress-linear
          v-if="!data.hydranten.length > 0"
          indeterminate
          style="position:absolute;z-index:1;"
        ></v-progress-linear>
        <div
          v-if="status_setLocation"
          class="markLocation"
        ></div>
        <l-map
        v-if="showMap && data.settings"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="height: 100%;z-index:0;"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        >
            <l-control>
              <div align="center">
                <v-btn
                  @click="homeLocation"
                >
                  <v-icon>mdi-home</v-icon>
                </v-btn>
                <template
                  v-if="!status_editHydrant"
                >
                  <v-divider class="my-2" />
                  <v-btn
                    @click="$router.push({ path: `/310-030?lat=${center.lat}&lon=${center.lng}` });"
                  >
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <template
                  v-if="status_editHydrant"
                >
                  <v-divider class="my-2" />
                  <v-btn
                    @click="centerLocation"
                  >
                      <v-icon small>mdi-crosshairs-gps</v-icon>
                  </v-btn>
                  <v-divider class="my-2" />
                  <v-btn
                    small
                    @click="
                      $router.push(
                        { path: `/310-030?h=${idHydrant}&lat=${center.lat}&lon=${center.lng}` }
                      )
                    "
                  >
                      <v-icon small left>mdi-crosshairs</v-icon>
                      Übernehmen
                  </v-btn>
                  <br>
                  <v-btn
                    small
                    @click="$router.push({ path: `/310-030?h=${idHydrant}` })"
                    class="mt-2"
                  >
                      <v-icon small left color="red">mdi-crosshairs-off</v-icon>
                      Abbrechen
                  </v-btn>
                </template>
                <template
                  v-if="!status_setLocation"
                >
                  <v-divider class="my-2" />
                  <v-btn
                    @click="activateStatusSetLocation"
                  >
                    <v-icon>mdi-crosshairs-question</v-icon>
                  </v-btn>
                </template>
                <template
                  v-if="status_setLocation && !status_editHydrant"
                >
                  <v-divider class="my-2" />
                  <v-btn
                    small
                    @click="centerLocation"
                  >
                      <v-icon small>mdi-crosshairs-gps</v-icon>
                  </v-btn>
                  <br>
                  <v-btn
                    small
                    @click="centerLocationOff"
                    class="mt-2"
                  >
                      <v-icon small color="red">mdi-crosshairs-off</v-icon>
                  </v-btn>
                </template>
              </div>
            </l-control>
            <l-tile-layer
                :url="url"
                :attribution="attribution"
            />
            <l-marker
                v-for="(hydrant, idx) in data.hydranten.filter((o) => o.id > 0)"
                :key="idx"
                :lat-lng="hydrant.coords"
                :icon="hydrant.icon"
            >
                <l-popup>
                <div :title="`${hydrant.durchmesser} - ${hydrant.info}`">
                  <b>
                    H{{ hydrant.durchmesser }}
                  </b>
                  <br>
                  {{ data.strassen.filter((o)=> o.id === hydrant.idStrasse)[0].strasse }}
                  {{ hydrant.hausnummer }},<br>
                  {{ data.strassen.filter((o)=> o.id === hydrant.idStrasse)[0].plz }}
                  {{ data.strassen.filter((o)=> o.id === hydrant.idStrasse)[0].ort }}
                </div>
                <v-row>
                  <v-col align="center">
                    <v-btn
                      x-small
                      class="mt-2"
                      @click="$router.push({path: `/310-030?h=${hydrant.id}&t=1`})"
                    >Eigenschaften</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0">
                    <!-- <v-select
                      dense
                      v-if="data"
                      v-model="hydrant.mangel"
                      :items="data.maengel"
                      label="Mangel"
                      item-text="name"
                      item-value="schluessel"
                      @change="tryUpdateMangel(hydrant.id, hydrant.mangel);"
                      >
                    </v-select> -->
                    <v-select
                      dense
                      v-if="data"
                      v-model="hydrant.mangel"
                      :items="data.maengel.map((o) => o.schluessel)"
                      attach chips multiple hide-selected
                      label="Mängel"
                      @change="tryUpdateMangel(hydrant.id, hydrant.mangel, hydrant);"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          label
                          small
                          >
                            <span class="pr-2">
                              {{
                                data.maengel.filter((o) => o.schluessel === item)[0].name
                              }}
                            </span>
                            <v-icon
                              small
                              @click.native.stop
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        {{
                          data.maengel.filter((o) => o.schluessel === item)[0].name
                        }}
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Keine weiteren Mängel.
                          </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0">
                    <v-select
                      dense
                      v-if="data"
                      v-model="hydrant.status"
                      :items="data.statuswerte"
                      label="Status"
                      item-text="name"
                      item-value="id"
                      @change="tryUpdateStatus(hydrant.id, hydrant.status);"
                      >
                    </v-select>
                  </v-col>
                </v-row>
                <br>
                <div>
                  Letztes Update:<br>
                  {{ hydrant.lastUpdate }}
                </div>
                </l-popup>
            </l-marker>
        </l-map>
    </div>
</template>

<script>
import $ from 'jquery';
import { latLng, icon } from 'leaflet';
import {
  LMap, LTileLayer, LMarker, LPopup, LControl,
} from 'vue2-leaflet';

export default {
  name: 'Example',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControl,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    idHydrant: {
      type: String,
      default: null,
    },
    propStatus_setLocation: {
      type: Boolean,
      default: false,
    },
    location: null,
  },
  data() {
    return {
      stillLoaded: false,
      lastLoadedUpdateCount_editHydrant: -1,
      status_setLocation: false,
      status_editHydrant: false,
      home: latLng(51.0160673, 7.0818574),
      zoom: 18,
      center: latLng(0, 0),
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 13,
      currentCenter: latLng(0, 0),
    };
  },
  watch: {
    data: {
      handler(neu) {
        if (!this.stillLoaded && neu.hydranten.length > 0) {
          this.stillLoaded = true;
          if (this.location.lat) {
            this.center = latLng(this.location.lat, this.location.lon);
            this.zoom = 18;
          } else if (this.idHydrant) {
            this.status_editHydrant = true;
            this.status_setLocation = true;
            this.zoom = 18;
            const hyd = this.data.hydranten.filter((o) => o.id === this.idHydrant)[0];
            setTimeout(() => {
              this.center = latLng(hyd.coords_lat, hyd.coords_lon);
            }, 500);
          } else {
            this.homeLocation();
          }
          if (this.propStatus_setLocation) {
            this.status_setLocation = true;
            this.zoom = 18;
          }
        }
        if (
          this.lastLoadedUpdateCount_editHydrant !== this.data.updateCount_editHydrant
          && this.idHydrant
        ) {
          this.status_editHydrant = true;
          this.status_setLocation = true;
          /* this.zoom = 18;
          const hyd = this.data.hydranten.filter((o) => o.id === this.idHydrant)[0];
          setTimeout(() => {
            this.center = latLng(hyd.coords_lat, hyd.coords_lon);
          }, 500); */
        }
      },
      deep: true,
    },
    idHydrant(neu) {
      if (!neu) {
        this.status_editHydrant = false;
        this.status_setLocation = false;
      }
    },
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.zoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
      this.center = center;
    },
    homeLocation() {
      if (this.data.settings) {
        this.center = latLng(this.data.settings.startpos_lat, this.data.settings.startpos_lon);
        this.zoom = parseInt(this.data.settings.startpos_zoom, 10);
      }
    },
    activateStatusSetLocation() {
      this.zoom = 18;
      this.status_setLocation = true;
    },
    centerLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((pos) => {
          this.zoom = 18;
          setTimeout(() => {
            this.center = latLng(pos.coords.latitude, pos.coords.longitude);
          }, 1);
          this.status_setLocation = true;
        }, (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Standortermittlung ist nicht aktiviert.',
            }, { root: true });
          }
        });
      } else {
        this.$store.commit('main/alert', {
          typ: 'error',
          text: 'Standortermittlung wird nicht unterstützt.',
        }, { root: true });
      }
    },
    centerLocationOff() {
      this.status_setLocation = false;
    },
    async tryUpdateStatus(id, status) {
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=saveHydrantChangeStatus`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          idHydrant: id,
          status,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.data.hydranten.filter((o) => o.id === id)[0].icon = icon({
              iconUrl: `img/300/Hydrantenpflege/mapMarkers/hydrant_status_${status}.png`,
              iconSize: [20, 20],
              iconAnchor: [10, 20],
            });
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Hydrant erfolgreich gespeichert!',
            }, { root: true });
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Hydrant konnte nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    async tryUpdateMangel(id, mangel, hydrant) {
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=saveHydrantChangeMangel`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          idHydrant: id,
          mangel: JSON.stringify(mangel),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            if (response.neuStatus) {
              this.data.hydranten.filter((o) => o.id === id)[0].status = response.neuStatus;
              this.data.hydranten.filter((o) => o.id === id)[0].icon = icon({
                iconUrl: `img/300/Hydrantenpflege/mapMarkers/hydrant_status_${response.neuStatus}.png`,
                iconSize: [20, 20],
                iconAnchor: [10, 20],
              });
            }
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Hydrant erfolgreich gespeichert!',
            }, { root: true });
            hydrant.lastUpdate = new Date().toLocaleString('de-DE', {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
            });
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Hydrant konnte nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
  },
  created() {
    this.$emit('reload');
  },
};
</script>

<style scoped>
    .component_karte {
        position:absolute;
        left:0px;
        width: 100%;
    }
    .component_karte_mobile {
        top:50px;
        height: calc(100% - 110px);
    }
    .component_karte_desktop {
        top:95px;
        height: calc(100% - 95px);
    }

    .markLocation {
      position:absolute;
      z-index:1;
      left:50%;top:50%;
      transform:translate(-50%, -50%);
      width:4px;height:4px;
      border-radius:50%;
      background-color:red;
      pointer-events:none;
    }
    .markLocation:after {
      content:'';
      position:absolute;
      left:0px;top:0px;
      width:20px;height:20px;
      border-radius:50%;
      transform:translate(-40%, -40%);
      border:solid red 1px;
    }
</style>
