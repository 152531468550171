export default {
  namespaced: true,
  state: {
    pushNotification: false,
  },
  getters: {
  },
  mutations: {
    load(state) {
      if (localStorage.getItem('f020')) {
        const store = JSON.parse(localStorage.getItem('f020'));
        Object.keys(store).forEach((k) => {
          state[k] = store[k];
        });
      }
    },
    changeState(state, { name, value }) {
      state[name] = value;
      let store = {};
      if (localStorage.getItem('f020')) {
        store = JSON.parse(localStorage.getItem('f020'));
      }
      store[name] = value;
      localStorage.setItem('f020', JSON.stringify(store));
    },
  },
  actions: {
  },
};
