<template>
  <v-dialog
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
    v-model="dialog_editAuftrag"
    width="1200"
  >
    <v-card>
      <v-card-title class="primary white--text" style="font-size:100%;">
        <v-container class="pa-0 d-flex justify-end">
          {{ editAuftrag.kundennummer }} - {{ editAuftrag.auftragsnummer }}
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog_delAuftrag"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text dark
                :loading="prop_isSaving_delAuftrag"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Auftrag löschen?
              </v-card-title>

              <v-card-text class="pt-4">
                Es werden sämtliche Daten und Dokumente gelöscht.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  text
                  @click="dialog_delAuftrag = false;"
                >
                  Nichts tun
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="
                    dialog_delAuftrag = false;
                    $emit('del', editAuftrag.id);
                  "
                >
                  löschen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            text dark
            @click="
              dialog_editAuftrag = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-container class="pa-0">
          <v-tabs
            fixed-tabs
            v-if="$vuetify.breakpoint.smAndDown"
            v-model="tabs_auftragsdaten_mobile"
            show-arrows
            color="primary"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            class="mb-2"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab>Info</v-tab>
            <v-tab>Dokumente</v-tab>
          </v-tabs>
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-if="
                tabs_auftragsdaten_mobile === 0 ||
                $vuetify.breakpoint.mdAndUp
              "
            >
              <v-row align="center">
                <v-col>
                  <p class="text-h6 pa-2 primary--text mb-0">
                    Auftrag: {{ editAuftrag.auftragsnummer }}
                  </p>
                  letzte Statusänderung:
                  <span v-if="editAuftrag.time_lastUpdate > 0">
                    {{
                      new Date(editAuftrag.time_lastUpdate * 1000).toLocaleString('de-DE', {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                        hour: 'numeric', minute: 'numeric',
                      })
                    }} Uhr
                  </span>
                  <span v-if="editAuftrag.time_lastUpdate <= 0">
                    --
                  </span>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="py-0">
                  <v-select
                    label="Auftragstyp"
                    :items="['Auftrag', 'Vertrag']"
                    v-model="editAuftrag.typ"
                  />
                </v-col>
                <v-col align="left" class="py-0">
                  <v-dialog
                    v-if="editAuftrag.leistungen.length > 0"
                    scrollable
                    v-model="dialog_ansicht1"
                    width="800"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        v-bind="attrs"
                        v-on="on"
                        text
                      >
                        <v-icon left>mdi-account-eye</v-icon>
                        Kostenübersicht
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="primary white--text">
                        <v-icon dark left>mdi-account-eye</v-icon>
                        Kostenübersicht
                      </v-card-title>

                      <v-card-text class="pt-4">
                        <div align="center">
                        <table>
                          <tr
                            v-for="(leistung, idx) in editAuftrag.leistungen"
                            :key="idx"
                            valign="top"
                          >
                            <td class="pa-1">{{ leistung.pos }}</td>
                            <td class="pa-1">
                              <b>{{ leistung.titel }}</b>
                              <p v-html="leistung.beschreibung.split('\n').join('<br>')"></p>
                            <!-- </td>
                            <td class="pa-1" width="90px" align="right"> -->
                              <div align="right">
                                <div
                                  v-if="leistung.anzahl > 1"
                                >
                                  {{ leistung.anzahl }}x
                                  {{ parseFloat(leistung.wert).toFixed(2).split('.').join(',') }} €
                                  <v-divider />
                                </div>
                                  {{ parseFloat(leistung.anzahl * leistung.wert)
                                  .toFixed(2).split('.').join(',') }} €
                                <div
                                  v-if="leistung.kosten > 0 && leistungenShowVerlustUndGewinn"
                                  class="red--text" style="font-size:60%;"
                                >
                                  - {{ parseFloat(leistung.kosten)
                                  .toFixed(2).split('.').join(',') }} €
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><hr></td>
                            <td><hr></td>
                          </tr>
                          <tr>
                            <td class="pa-1" align="right"></td>
                            <td class="pa-1" align="right">
                              <b>Summe Netto:</b>
                              {{ parseFloat(leistungenSumme)
                                .toFixed(2).split('.').join(',') }} €
                            </td>
                          </tr>
                          <tr>
                            <td class="pa-1" align="right"></td>
                            <td class="pa-1" align="right">
                              <b>zzgl. {{ leistungenMwSt }}% MwSt.:</b>
                              {{ parseFloat(leistungenSummeMwSt)
                                .toFixed(2).split('.').join(',') }} €
                            </td>
                          </tr>
                          <tr>
                            <td class="pa-1" align="right"></td>
                            <td class="pa-1" align="right">
                              <b>Summe Brutto:</b>
                              {{ parseFloat(leistungenSummeBrutto)
                                .toFixed(2).split('.').join(',') }} €
                            </td>
                          </tr>
                          <tr class="green--text"
                            v-if="leistungenShowVerlustUndGewinn"
                          >
                            <td class="pa-1" align="right"></td>
                            <td class="pa-1" align="right">
                              <b>Summe Gewinn:</b>
                              {{ parseFloat(leistungenSummeGewinn)
                                .toFixed(2).split('.').join(',') }} €
                            </td>
                          </tr>
                        </table>
                        </div>
                        <v-divider />
                        <div v-html="auftragsbeschreibungNl2br">
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          text
                          @click="dialog_ansicht1 = false;"
                        >
                          Schließen
                        </v-btn>
                        <v-spacer />
                        <v-checkbox
                          v-model="leistungenShowVerlustUndGewinn"
                        />
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="dialog_ansicht1 = false;"
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-autocomplete
                    :items="prop_kunden"
                    item-text="ident"
                    item-value="kundennummer"
                    label="Kunde"
                    v-model="editAuftrag.kundennummer"
                  />
                </v-col>
                <v-col class="py-0">
                  <v-select
                    v-if="editAuftrag.typ === 'Auftrag'"
                    :items="prop_settings.auftragsstatus"
                    item-text="kurz"
                    item-value="nummer"
                    label="Status"
                    v-model="editAuftrag.status"
                  />
                  <v-select
                    v-if="editAuftrag.typ === 'Vertrag'"
                    :items="prop_settings.vertragsstatus"
                    item-text="kurz"
                    item-value="nummer"
                    label="Status"
                    v-model="editAuftrag.status"
                  />
                </v-col>
              </v-row>
              <v-row v-if="editAuftrag.typ === 'Vertrag'">
                <v-col class="py-0">
                  <v-text-field
                    type="text"
                    label="Vertragsnummer"
                    v-model="editAuftrag.vertrag_nummer"
                    :rules="[
                      $store.state.main.inputValidationRules.name,
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="editAuftrag.typ === 'Vertrag'"
              >
                <v-col class="py-0">
                  <DatePicker
                    v-model="editAuftrag.vertrag_beginn"
                    label="Beginn"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="editAuftrag.typ === 'Vertrag'"
              >
                <v-col class="py-0 pb-4">
                  <DatePicker
                    v-model="editAuftrag.vertrag_ende"
                    label="Ende"
                  />
                  Ende Kündigungsfrist: {{ editAuftrag.vertrag_endeKuendigungsfrist }}
                </v-col>
                <v-col cols="2" class="py-0 pt-6">
                  <v-btn
                    x-small
                    @click="changedVertragBeginn();"
                  >
                    <v-icon small>mdi-auto-fix</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="editAuftrag.typ === 'Vertrag'"
              >
                <v-col class="py-0">
                  <v-select
                    :items="[
                      {value: '0', text: 'Monatlich'},
                      {value: '1', text: 'Jährlich'},
                      {value: '2', text: 'Quartalsweise'},
                      {value: '3', text: 'Halbjährlich'}
                    ]"
                    item-text="text"
                    item-value="value"
                    label="Zahlungsintervall"
                    v-model="editAuftrag.vertrag_zahlungJaehrlich"
                  />
                </v-col>
              </v-row>
              <v-text-field
                type="text"
                label="Titel"
                v-model="editAuftrag.titel"
                :rules="[
                  $store.state.main.inputValidationRules.name,
                ]"
              ></v-text-field>
              <v-textarea
                auto-grow
                label="Info"
                v-model="editAuftrag.beschreibung"
                hint="Öffentliche Info für den Kunden"
              ></v-textarea>
              <v-textarea
                auto-grow
                label="Admin Info"
                v-model="editAuftrag.adminInfo"
                hint="Private Info (Nicht für den Kunden sichtbar)"
              ></v-textarea>
              <v-row class="mt-4">
                <v-col>
                  <v-expansion-panels v-model="editAuftrag_extPan_leistungen">
                    <v-expansion-panel
                      v-for="(leistung, idx) in editAuftrag.leistungen"
                      :key="idx"
                    >
                      <v-expansion-panel-header disable-icon-rotate>
                        <template v-slot:actions>
                          <v-btn small text color="primary"
                            @click="delLeistung(idx)"
                          >
                            <v-icon>mdi-minus-box</v-icon>
                          </v-btn>
                        </template>
                        #{{ leistung.pos }}&nbsp;<b>{{ leistung.titel }}</b>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col class="py-0">
                            <v-select
                              label="Leistungstyp"
                              :items="prop_settings.leistungstypen"
                              v-model="leistung.id_leistungstyp"
                              item-text="nameMitMwSt"
                              item-value="id"
                              @change="leistungChangeLeistungstyp(leistung);"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-select
                              label="Einnahmestelle"
                              :items="prop_settings.einnahmestellen"
                              v-model="leistung.id_einnahmestelle"
                              item-text="name"
                              item-value="id"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0" cols="3">
                            <v-text-field
                              type="number"
                              label="Pos."
                              v-model="leistung.pos"
                              :rules="[
                                $store.state.main.inputValidationRules.nummer,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Titel"
                              v-model="leistung.titel"
                              :rules="[]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0" cols="3">
                            <v-text-field
                              type="number"
                              label="Anzahl"
                              v-model="leistung.anzahl"
                              :rules="[
                                $store.state.main.inputValidationRules.nummer,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0">
                            <v-text-field
                              type="number"
                              label="Nettowert in €"
                              v-model="leistung.wert"
                              :rules="[
                                $store.state.main.inputValidationRules.nummer,
                              ]"
                              @change="nettoInBrutto(leistung);"
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0">
                            <v-text-field
                              type="number"
                              label="Bruttowert in €"
                              v-model="leistung.wertBrutto"
                              :rules="[
                                $store.state.main.inputValidationRules.nummer,
                              ]"
                              @change="bruttoInNetto(leistung);"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col class="py-0" cols="3" align="center">
                            <v-dialog
                              scrollable
                              v-model="leistung.dialog_selectKosten"
                              width="500"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                >
                                  <v-icon v-if="
                                    (
                                      leistung.id_kosten === '0'
                                      || !leistung.id_kosten
                                    )
                                    && (
                                      leistung.tabs_neuKosten === 0
                                      || !leistung.tabs_neuKosten
                                    )
                                  ">
                                    mdi-text-search
                                  </v-icon>
                                  <v-icon v-if="
                                    (
                                      leistung.id_kosten
                                      && leistung.id_kosten !== '0'
                                    )
                                    || leistung.tabs_neuKosten === 1
                                  "
                                  color="success">
                                    mdi-playlist-check
                                  </v-icon>
                                </v-btn>
                              </template>

                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Kosten verknüpfen
                                </v-card-title>

                                <v-card-text class="pt-4">
                                  <v-tabs
                                    fixed-tabs
                                    v-model="leistung.tabs_neuKosten"
                                    @change="changeLeistungKosten(leistung);"
                                  >
                                    <v-tab>Wählen</v-tab>
                                    <v-tab>Neu</v-tab>
                                  </v-tabs>
                                  <v-autocomplete
                                    clearable
                                    v-if="leistung.tabs_neuKosten === 0"
                                    label="Kosten wählen"
                                    v-model="leistung.id_kosten"
                                    :items="prop_kosten"
                                    item-text="titel2"
                                    item-value="id"
                                    no-data-text="Keine Suchergebnisse gefunden"
                                    @change="changeLeistungKosten(leistung);"
                                  />
                                  <template v-if="leistung.tabs_neuKosten === 1">
                                    <DatePicker
                                      v-model="leistung.neuKosten.datum"
                                    />
                                    <v-select
                                      :items="prop_settings.kostenstellen"
                                      item-text="name"
                                      item-value="id"
                                      label="Kostenstelle"
                                      v-model="leistung.neuKosten.id_kostenstelle"
                                    />
                                    <v-row>
                                      <v-col class="py-0">
                                        <v-text-field
                                          type="number"
                                          label="Wert [€]"
                                          v-model="leistung.neuKosten.betrag"
                                          :rules="[
                                            $store.state.main.inputValidationRules.nummer,
                                          ]"
                                          @change="changeLeistungKosten(leistung);"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col class="py-0">
                                        <v-text-field
                                          type="number"
                                          label="enthaltene MwSt [%]"
                                          v-model="leistung.neuKosten.mwst"
                                          :rules="[
                                            $store.state.main.inputValidationRules.nummer,
                                          ]"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-text-field
                                      type="text"
                                      label="Titel"
                                      v-model="leistung.neuKosten.titel"
                                      :rules="[
                                        $store.state.main.inputValidationRules.name,
                                      ]"
                                    ></v-text-field>
                                    <v-textarea
                                      auto-grow
                                      label="Beschreibung"
                                      v-model="leistung.neuKosten.beschreibung"
                                      hint=""
                                    ></v-textarea>
                                  </template>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-btn
                                    text
                                    @click="leistung.dialog_selectKosten = false;"
                                  >
                                    Schließen
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text color="primary"
                                    @click="leistung.dialog_selectKosten = false;"
                                  >
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-col>
                          <v-col class="py-0">
                            <v-text-field
                              type="number"
                              label="Kosten in €"
                              v-model="leistung.kosten"
                              :rules="[
                                $store.state.main.inputValidationRules.nummer,
                              ]"
                              :disabled="
                                (
                                  leistung.id_kosten
                                  && leistung.id_kosten !== '0'
                                )
                                || leistung.tabs_neuKosten === 1
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-textarea
                              auto-grow
                              label="Leistungsbeschreibung"
                              v-model="leistung.beschreibung"
                              hint=""
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div align="center">
                    <v-btn small text color="primary" class="ma-2"
                      @click="addLeistung();"
                    >
                      <v-icon left>mdi-plus-box</v-icon>
                      Leistung
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="
                tabs_auftragsdaten_mobile === 1 ||
                $vuetify.breakpoint.mdAndUp
              "
            >
              <p class="text-h6 pa-2 primary--text mb-0">
                Dokumente
              </p>
              <v-dialog
                scrollable
                v-model="dialog_neuDokument"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    <v-icon left>mdi-plus-box</v-icon>
                    Dokument anhängen
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="primary white--text">
                    <v-icon left dark>mdi-paperclip</v-icon>
                    Dokument anhängen
                  </v-card-title>

                  <v-card-text class="pt-4">
                    <v-select
                      label="Art des Anhangs"
                      :items="prop_settings.auftraegeDokumentarten.filter((
                        (o) => {
                          if (
                            o.fuerKosten !== '1'
                            || o.nummer === '1'
                            || o.nummer === '100'
                          ) {
                            return o;
                          }
                          return null;
                        }
                      ))"
                      v-model="neuDokument.dokumentart"
                      item-text="kurz"
                      item-value="nummer"
                    />
                    <template
                      v-if="
                        neuDokument.dokumentart === '1'
                        || neuDokument.dokumentart === '2'
                      "
                    >
                      <DatePicker
                        v-model="neuDokument.datum"
                        @change="neuDokumentGetMoeglicheUhrzeiten();"
                      />
                      <v-row
                        v-if="
                          neuDokument.showTerminPlanen
                          && neuDokument.terminPlanenMoeglicheUhrzeiten.length > 0
                          && prop_settings.rechnungen_planenCronjobsMoeglich
                        "
                      >
                        <v-col class="py-0">
                          <v-checkbox
                            :label="`${neuDokument.dokumentartName}serstellung planen`"
                            v-model="neuDokument.terminPlanen"
                          />
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="neuDokument.terminPlanen"
                      >
                        <v-col class="py-0"
                          v-if="editAuftrag.typ === 'Vertrag'"
                        >
                          <v-select
                            :label="`Wiederholen`"
                            :items="[
                              { value: '0', text: `Einmalig (${neuDokumentDatumDE})` },
                              { value: '1', text: 'Zahlungsintervall (Regelmäßig)' },
                            ]"
                            v-model="neuDokument.terminPlanenWiederholen"
                            @change="neuDokumentGetMoeglicheUhrzeiten();"
                          />
                          <p
                            v-if="
                              editAuftrag.vertrag_zahlungJaehrlich === '0'
                              && neuDokument.terminPlanenWiederholen === '1'
                            "
                          >
                            {{ neuDokument.dokumentartName }} wird monatlich ab
                            Vertragsbeginn erstellt.
                          </p>
                          <p
                            v-if="
                              editAuftrag.vertrag_zahlungJaehrlich === '1'
                              && neuDokument.terminPlanenWiederholen === '1'
                            "
                          >
                            {{ neuDokument.dokumentartName }}
                            wird zum Vertragsbeginn sowie jährlich zur
                            Vertragsverlängerung erstellt.
                          </p>
                          <p
                            v-if="
                              editAuftrag.vertrag_zahlungJaehrlich === '2'
                              && neuDokument.terminPlanenWiederholen === '1'
                            "
                          >
                            {{ neuDokument.dokumentartName }}
                            wird quartalsweise ab
                            Vertragsbeginn erstellt.
                          </p>
                          <p
                            v-if="
                              editAuftrag.vertrag_zahlungJaehrlich === '3'
                              && neuDokument.terminPlanenWiederholen === '1'
                            "
                          >
                            {{ neuDokument.dokumentartName }}
                            wird halbjährlich ab
                            Vertragsbeginn erstellt.
                          </p>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="neuDokument.terminPlanen"
                      >
                        <v-col class="py-0">
                          <v-autocomplete
                            label="Uhrzeit"
                            :items="neuDokument.terminPlanenMoeglicheUhrzeiten"
                            v-model="neuDokument.terminPlanenUhrzeit"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <v-checkbox
                            v-model="neuDokument.automatischeReAnNummer"
                            :label="`Automatische ${neuDokument.dokumentartName}snummer`"
                          />
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="!neuDokument.automatischeReAnNummer"
                      >
                        <v-col
                          class="py-0"
                        >
                          <v-text-field
                            type="number"
                            :label="`${neuDokument.dokumentartName}snummer`"
                            v-model="neuDokument.reAnNummer"
                            :rules="[
                              $store.state.main.inputValidationRules.nummer,
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-checkbox
                        label="Individuelle Anschrift"
                        v-model="neuDokument.useIndividuelleAnschrift"
                      />
                      <v-select
                        no-data-text="Keine Anschriften vorhanden"
                        v-if="!neuDokument.useIndividuelleAnschrift"
                        label="Anschrift"
                        :items="neuDokument.moeglicheAnschriften"
                        v-model="neuDokument.anschriftId"
                        item-text="bezeichnung"
                        item-value="id"
                        @change="neuDokumentChangeAnschrift();"
                      />
                      <v-select
                        no-data-text="Keine Ansprechpartner vorhanden"
                        v-if="!neuDokument.useIndividuelleAnschrift"
                        label="Ansprechpartner"
                        :items="neuDokument.moeglicheAnsprechpartner"
                        v-model="neuDokument.ansprechpartnerId"
                        item-text="ident"
                        item-value="id"
                        @change="neuDokumentChangeAnsprechpartner();"
                      />
                      <v-textarea
                        v-if="neuDokument.useIndividuelleAnschrift"
                        auto-grow
                        label="Anschrift"
                        v-model="neuDokument.individuelleAnschrift"
                        hint=""
                        max-height="5"
                      ></v-textarea>
                      <v-select
                        multiple
                        label="Textblöcke"
                        :items="prop_settings.rechnungenTextbloecke"
                        item-text="kurz"
                        item-value="id"
                        v-model="neuDokument.textbloecke"
                        no-data-text="Keine Textblöcke vorhanden"
                      />
                      <v-textarea
                        v-if="neuDokument.textbloecke.includes('1')"
                        auto-grow
                        label="individueller Textblock"
                        v-model="neuDokument.individuellerTextblock"
                        hint=""
                      ></v-textarea>
                      <v-text-field
                        type="number"
                        label="Anzahlung in €"
                        v-model="neuDokument.anzahlung"
                        :rules="[
                          $store.state.main.inputValidationRules.nummer,
                        ]"
                      ></v-text-field>
                      <v-row
                        v-if="
                          neuDokument.dokumentart === '2'
                        "
                      >
                        <v-col class="py-0">
                          <v-select
                            :label="`Unterschrift`"
                            :items="prop_settings.unterschriften"
                            item-value="nummer"
                            item-text="name"
                            v-model="neuDokument.angebotUnterschriftAuftragnehmer"
                          />
                        </v-col>
                      </v-row>
                      <v-checkbox
                        v-if="neuDokument.ansprechpartner.mail !== ''"
                        :label="`${neuDokument.dokumentartName} per E-Mail senden`"
                        v-model="neuDokument.terminPlanenSenden"
                      />
                      <div v-if="neuDokument.terminPlanenSenden">
                        An: {{ neuDokument.ansprechpartner.mail }}
                      </div>
                      <v-text-field
                        v-if="neuDokument.terminPlanenSenden && 1 === 2"
                        type="text"
                        label="Betreff"
                        v-model="neuDokument.terminPlanenSendenBetreff"
                        :rules="[
                          $store.state.main.inputValidationRules.name,
                        ]"
                      ></v-text-field>
                      <v-textarea
                        v-if="neuDokument.terminPlanenSenden"
                        auto-grow
                        label="E-Mail Text (optional)"
                        v-model="neuDokument.terminPlanenSendenText"
                        hint="Wird an den automatisch generierten Text angehängt."
                      ></v-textarea>
                    </template>
                    <template
                      v-if="
                        neuDokument.dokumentart === '100'
                      "
                    >
                      <!-- Dokument hochladen -->
                      <UploadBilderPDF
                        :apipath="
                          `400/410/neuDokument/?key=${this.$store.state.login.session.code}&fill=
                          &id_a=${neuDokument.id_auftrag}`
                        "
                        accept="application/pdf, image/*"
                        @neuDokumente="addDokumente($event);"
                      />
                    </template>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      text small
                      @click="dialog_neuDokument = false;"
                    >
                      Schließen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        neuDokument.dokumentart !== '100'
                      "
                      color="primary"
                      text
                      @click="trySaveNeuDokument();"
                      :loading="loading_saveDokument"
                    >
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-data-table
                :headers="headers"
                :items="
                  editAuftrag.dokumente
                "
                :search="search"
                :loading="loading"
                :items-per-page="10"
                :header-props="headerProps"
                :footer-props="footerProps"
                no-data-text="Keine Dokumente gefunden"
                no-results-text="Keine Dokumente gefunden"
                loading-text="Lade Daten... Bitte warten"
                mobile-breakpoint="600"
              >
                <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                  {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
                </template>
                <template v-slot:item.dokumentart="{ item }">
                  <v-row style="font-size:80%;">
                    <v-icon small
                      left color="red"
                      v-if="
                        item.dokumentart === 1
                        || item.dokumentart === 2
                      "
                    >mdi-file-pdf-box</v-icon>
                    <span
                      v-if="item.dokumentart === 1"
                    >Rechnung</span>
                    <span
                      v-if="item.dokumentart === 2"
                    >Angebot</span>
                    <v-icon small
                      left color="success"
                      v-if="
                        item.dokumentart === 20
                      "
                    >mdi-receipt-text-check-outline</v-icon>
                    <span
                      v-if="item.dokumentart === 20"
                    >Buchungsbeleg</span>
                    <v-icon small
                      left
                      v-if="
                        item.dokumentart === 100
                      "
                    >mdi-upload</v-icon>
                    <span
                      v-if="item.dokumentart === 100"
                    >Hochgeladen</span>
                </v-row>
                <v-row class="ps-2">
                  {{ item.name }}
                </v-row>
                </template>
                <template v-slot:item.upload="{ item }">
                  {{item.dateUpload }} {{item.timeUpload }}
                </template>
                <template v-slot:item.aktion="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-dialog
                        v-if="!item.geplanteRechnung"
                        v-model="dialog_editDokument[item.id]"
                        width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-btn text
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn> -->
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>
                              <v-icon left>mdi-pencil</v-icon>
                              Bearbeiten
                            </v-list-item-title>
                          </v-list-item>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Dokument bearbeiten
                          </v-card-title>

                          <v-card-text class="pt-4">
                            <v-select
                              label="Art des Dokuments"
                              :items="prop_settings.auftraegeDokumentarten"
                              v-model="item.dokumentart"
                              item-text="name"
                              item-value="nummer"
                            />
                            <DatePicker
                              v-model="item.dateUpload2"
                              @change="changeDokumentDatum(item);"
                            />
                            <v-text-field
                              type="text"
                              label="Titel"
                              v-model="item.name"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                            <v-text-field
                              type="number"
                              label="Wert"
                              v-model="item.wert"
                              :rules="[
                                $store.state.main.inputValidationRules.nummer,
                              ]"
                            ></v-text-field>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              text
                              @click="dialog_editDokument[item.id] = false;"
                            >
                              Schließen
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              text color="primary"
                              @click="dialog_editDokument[item.id] = false;"
                            >
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-list-item
                        @click="tryDownloadDokument(item.pfad, item.name, true);"
                      >
                        <v-list-item-title>
                          <v-icon left>mdi-eye-outline</v-icon>
                          Ansehen
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="tryDownloadDokument(item.pfad, item.name, false);"
                      >
                        <v-list-item-title>
                          <v-icon left>mdi-download</v-icon>
                          Download
                        </v-list-item-title>
                      </v-list-item>
                      <v-dialog
                        v-model="dialog_delDokument[item.id]"
                        width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-btn text color="error"
                            v-bind="attrs"
                            v-on="on"
                            :loading="item.loadingDelete"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn> -->
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                            :loading="item.loadingDelete"
                          >
                            <v-list-item-title>
                              <v-icon left>mdi-delete</v-icon>
                              Löschen
                            </v-list-item-title>
                          </v-list-item>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Dokument löschen?
                          </v-card-title>

                          <v-card-text class="pt-4">
                            Das Dokument wird unwiderruflich gelöscht.
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              text
                              @click="dialog_delDokument[item.id] = false;"
                            >
                              Nichts tun
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              text
                              @click="
                                dialog_delDokument[item.id] = false;
                                tryDelDokument(item.id, item.pfad);
                              "
                            >
                              löschen
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list>
                  </v-menu>
                  <!-- <v-btn text color="primary"
                    v-if="!item.geplanteRechnung"
                    @click="tryDownloadDokument(item.pfad, item.name, true);"
                    :loading="item.loadingDownload"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-container
          class="pa-0 d-flex justify-end"
        >
          <v-btn
            text small
            @click="
              dialog_editAuftrag = false;
            "
          >
            schließen
          </v-btn>
          <v-btn
            color="primary"
            text small
            @click="
              $emit('save', editAuftrag);
            "
            :disabled="xLoaded === JSON.stringify(editAuftrag)"
            :loading="prop_isSaving_editAuftrag"
          >
            Speichern
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import $ from 'jquery';
import UploadBilderPDF from '@/components/Inputs/UploadBilderPDF.vue';
import DatePicker from '@/components/Inputs/DatePicker.vue';

export default {
  components: {
    UploadBilderPDF,
    DatePicker,
  },
  props: {
    prop_auftrag: {
      type: Object,
      default() { return {}; },
    },
    prop_isSaving_editAuftrag: {
      type: Boolean,
      default: false,
    },
    prop_isSaving_delAuftrag: {
      type: Boolean,
      default: false,
    },
    prop_kunden: {
      type: Array,
      default() { return []; },
    },
    prop_kosten: {
      type: Array,
      default() { return []; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    leistungenShowVerlustUndGewinn: false,
    loading_saveDokument: false,
    loading_delDokument: {},
    dialog_editDokument: {},
    dialog_ansicht1: false,
    dialog_delDokument: {},
    dialog_neuDokument: false,
    tabs_auftragsdaten_mobile: null,
    dialog_editAuftrag: false,
    dialog_delAuftrag: false,
    editAuftrag_extPan_leistungen: null,
    editAuftrag: {},
    neuKosten: {},
    tabs_neuKosten: null,
    xLoaded: '',
    neuDokument: {},
    neuDokumentIdAnschrift: '',
    loading: false,
    search: '',
    headers: [
      // { text: 'Auftrag', value: 'auftragsnummer', width: '150px' },
      {
        text: '', value: 'aktion', sortable: false, width: '80px',
      },
      { text: 'Art', value: 'dokumentart' },
      // { text: 'Name', value: 'name' },
      { text: 'Upload', value: 'upload' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
    leistungenSumme() {
      let summe = 0;
      this.editAuftrag.leistungen.forEach((l) => {
        summe += parseFloat(l.wert) * parseFloat(l.anzahl);
      });
      return summe;
    },
    leistungenSummeGewinn() {
      let summe = 0;
      this.editAuftrag.leistungen.forEach((l) => {
        summe += parseFloat(l.wert) * parseFloat(l.anzahl) - parseFloat(l.kosten);
      });
      return summe;
    },
    leistungenSummeMwSt() {
      let summe = 0;
      this.editAuftrag.leistungen.forEach((l) => {
        summe += parseFloat(l.wert) * parseFloat(l.anzahl) * (0 + parseFloat(l.mwst) / 100);
      });
      return summe;
    },
    leistungenMwSt() {
      let mwst = 0;
      this.editAuftrag.leistungen.forEach((l) => {
        if (l.mwst > mwst) {
          mwst = parseFloat(l.mwst);
        }
      });
      return mwst;
    },
    leistungenSummeBrutto() {
      let summe = 0;
      this.editAuftrag.leistungen.forEach((l) => {
        summe += parseFloat(l.wert) * parseFloat(l.anzahl) * (1 + parseFloat(l.mwst) / 100);
      });
      return summe;
    },
    neuDokumentDatumDE() {
      const datum = this.neuDokument.datum.split('-');
      return `${datum[2]}.${datum[1]}.${datum[0]}`;
    },
    neuDokumentDatumVertragsbeginnDE() {
      const datum = this.editAuftrag.vertrag_beginn.split('-');
      return `${datum[2]}.${datum[1]}.${datum[0]}`;
    },
    auftragsbeschreibungNl2br() {
      return this.editAuftrag.beschreibung.split('\n').join('<br>');
    },
  },
  watch: {
    prop_auftrag: {
      handler(neu) {
        this.editAuftrag.leistungen.forEach((l) => {
          if (neu.leistungen.filter((o) => o.pos === l.pos).length > 0) {
            const i = neu.leistungen.filter((o) => o.pos === l.pos)[0];
            l.id_kosten = i.id_kosten;
            l.tabs_neuKosten = i.tabs_neuKosten;
            l.betrag = i.betrag;
          }
        });
        // this.editAuftrag.leistungen = neu.leistungen;
      },
      deep: true,
    },
    editAuftrag: {
      handler() {
        this.changedVertragEnde();
      },
      deep: true,
    },
    dialog_editAuftrag(neu) {
      if (neu === false) {
        setTimeout(() => {
          this.$emit('close');
        }, 400);
      }
    },
    neuDokument: {
      handler() {
        this.updateNeuDokument();
      },
      deep: true,
    },
    dialog_neuDokument(neu) {
      if (neu === true) {
        this.resetNeuDokument();
      }
    },
  },
  methods: {
    changeDokumentDatum(dokument) {
      const d = dokument.dateUpload2.split('-');
      dokument.dateUpload = `${d[2]}.${d[1]}.${d[0]}`;
      dokument.upload = `${d[2]}.${d[1]}.${d[0]} ${dokument.timeUpload}`;
    },
    changeLeistungKosten(leistung) {
      if (leistung.tabs_neuKosten === 0) {
        if (this.prop_kosten.filter((k) => k.id === leistung.id_kosten).length > 0) {
          leistung.kosten = this.prop_kosten.filter((k) => k.id === leistung.id_kosten)[0].betrag;
        } else {
          leistung.kosten = 0.00;
        }
      } else {
        leistung.kosten = leistung.neuKosten.betrag;
      }
    },
    sortDokumente() {
      this.editAuftrag.dokumente = this.editAuftrag.dokumente.sort((a, b) => {
        if (a.upload < b.upload) {
          return 1;
        }
        if (a.upload > b.upload) {
          return -1;
        }
        return 0;
      });
    },
    addDokumente(dokumente) {
      dokumente.forEach((d) => {
        this.editAuftrag.dokumente.push(d);
        this.prop_auftrag.dokumente.push(d);
      });
      this.dialog_neuDokument = false;
    },
    changedVertragBeginn() {
      const now = new Date(this.editAuftrag.vertrag_beginn);
      const dd = String(now.getDate()).padStart(2, '0');
      const mm = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = now.getFullYear() + 1; // Plus 1 Jahr
      this.editAuftrag.vertrag_ende = `${yyyy}-${mm}-${dd}`;
      const then = new Date(`${yyyy}-${mm}-${dd}`);
      then.setDate(then.getDate() - 4 * 7); // - 4 Wochen Kündigungsfrist
      const dd2 = String(then.getDate()).padStart(2, '0');
      const mm2 = String(then.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy2 = then.getFullYear(); // Plus 1 Jahr
      this.editAuftrag.vertrag_endeKuendigungsfrist = `${dd2}.${mm2}.${yyyy2}`;
    },
    changedVertragEnde(datum) {
      let d;
      if (!datum) {
        d = this.editAuftrag.vertrag_ende;
      } else {
        d = datum;
      }
      const then = new Date(d);
      then.setDate(then.getDate() - 4 * 7); // - 4 Wochen Kündigungsfrist
      const dd2 = String(then.getDate()).padStart(2, '0');
      const mm2 = String(then.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy2 = then.getFullYear(); // Plus 1 Jahr
      this.editAuftrag.vertrag_endeKuendigungsfrist = `${dd2}.${mm2}.${yyyy2}`;
    },
    leistungChangeLeistungstyp(leistung) {
      const [typ] = this.prop_settings.leistungstypen
        .filter((o) => o.id === leistung.id_leistungstyp);
      leistung.mwst = typ.mwst;
    },
    bruttoInNetto(leistung) {
      /* const [typ] = this.prop_settings.leistungstypen
        .filter((o) => o.id === leistung.id_leistungstyp);
      leistung.wert = parseInt(leistung.wertBrutto, 10) / (1 + typ.mwst / 100); */
      leistung.wert = parseFloat(leistung.wertBrutto, 10) / (1 + parseFloat(leistung.mwst) / 100);
    },
    nettoInBrutto(leistung) {
      /* const [typ] = this.prop_settings.leistungstypen
        .filter((o) => o.id === leistung.id_leistungstyp);
      leistung.wertBrutto = parseInt(leistung.wert, 10) * (1 + typ.mwst / 100); */
      leistung.wertBrutto = parseFloat(leistung.wert, 10) * (1 + parseFloat(leistung.mwst) / 100);
    },
    delLeistung(idx) {
      this.editAuftrag.leistungen.splice(idx, 1);
      this.editAuftrag_extPan_leistungen = null;
    },
    addLeistung() {
      const heute = new Date();
      const dd = String(heute.getDate()).padStart(2, '0');
      const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = heute.getFullYear();
      this.editAuftrag.leistungen.push({
        id: 0,
        id_auftrag: this.editAuftrag.id,
        id_leistungstyp: this.prop_settings.leistungstypen[0].id,
        id_einnahmestelle: this.prop_settings.einnahmestellen[0].id,
        pos: this.editAuftrag.leistungen.length + 1,
        titel: '',
        beschreibung: '',
        anzahl: 1,
        wert: 0,
        wertBrutto: 0,
        dialog_selectKosten: false,
        kosten: 0,
        neuKosten: {
          datum: `${yyyy}-${mm}-${dd}`,
          mwst: '19',
          id_kostenstelle: this.prop_settings.kostenstellen[0].id,
          betrag: 0,
          titel: '',
          beschreibung: '',
        },
        mwst: this.prop_settings.leistungstypen[0].mwst,
      });
      this.editAuftrag_extPan_leistungen = this.editAuftrag.leistungen.length - 1;
    },
    neuDokumentChangeDatum() {
      const heute = new Date();
      const dd = String(heute.getDate()).padStart(2, '0');
      const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = heute.getFullYear();
      const heute0 = new Date(`${yyyy}-${mm}-${dd}`);
      const termin = new Date(this.neuDokument.datum);
      if (heute0.getTime() - termin.getTime() <= 0) {
        this.neuDokument.showTerminPlanen = true;
      } else {
        this.neuDokument.showTerminPlanen = false;
      }
    },
    neuDokumentChangeAnsprechpartner() {
      [this.neuDokument.ansprechpartner] = this.neuDokument.moeglicheAnsprechpartner
        .filter((o) => o.id === this.neuDokument.ansprechpartnerId);
    },
    neuDokumentChangeAnschrift() {
      [this.neuDokument.anschrift] = this.neuDokument.moeglicheAnschriften
        .filter((o) => o.id === this.neuDokument.anschriftId);
    },
    neuDokumentGetMoeglicheUhrzeiten() {
      const help = [
        '00:00', '01:00', '02:00', '03:00',
        '04:00', '05:00', '06:00', '07:00',
        '08:00', '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00',
        '16:00', '17:00', '18:00', '19:00',
        '20:00', '21:00', '22:00', '23:00',
      ];
      const heute = new Date();
      const dd = String(heute.getDate()).padStart(2, '0');
      const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = heute.getFullYear();
      const heute0 = new Date(`${yyyy}-${mm}-${dd}`);
      const termin = new Date(this.neuDokument.datum);
      if (
        heute0.getTime() === termin.getTime()
        && this.neuDokument.terminPlanenWiederholen !== '1'
      ) {
        const help2 = JSON.parse(JSON.stringify(help));
        help2.forEach((u) => {
          if (u <= `${String(heute.getHours() + 1).padStart(2, '0')}:00`) {
            help.splice(help.indexOf(u), 1);
          }
        });
        if (help.length <= 0) {
          this.neuDokument.showTerminPlanen = false;
          this.neuDokument.terminPlanen = false;
        } else {
          [this.neuDokument.terminPlanenUhrzeit] = help;
        }
      } else {
        this.neuDokument.terminPlanenUhrzeit = '06:00';
      }
      this.neuDokument.terminPlanenMoeglicheUhrzeiten = help;
    },
    updateNeuDokument() {
      if (
        this.prop_settings.auftraegeDokumentarten
          .filter((o) => o.nummer === this.neuDokument.dokumentart).length > 0
      ) {
        [this.neuDokument.dokumentartName] = this.prop_settings.auftraegeDokumentarten
          .filter((o) => o.nummer === this.neuDokument.dokumentart)[0].kurz
          .split(' ');
      } else {
        this.neuDokument.dokumentartName = '';
      }
      // this.neuDokument
      //  .terminPlanenSendenBetreff = `${this.neuDokument.dokumentartName} [[DOKUMENTNAME]]`;
      this.neuDokumentChangeDatum();
    },
    resetNeuDokument() {
      this.neuDokument = {
        automatischeReAnNummer: true,
        reAnNummer: 0,
        kundennummer: 0,
        id_auftrag: 0,
        dokumentart: 0,
        dokumentartName: '',
        datum: '',
        showTerminPlanen: false,
        terminPlanen: false,
        terminPlanenMoeglicheUhrzeiten: [],
        terminPlanenUhrzeit: '06:00',
        terminPlanenSenden: false,
        terminPlanenSendenMail: '',
        terminPlanenSendenText: '',
        terminPlanenSendenBetreff: '',
        terminPlanenWiederholen: '0',
        ansprechpartnerId: 0,
        ansprechpartner: {},
        moeglicheAnsprechpartner: [],
        anschriftId: 0,
        anschrift: {},
        moeglicheAnschriften: [],
        leistungen: [],
        useIndividuelleAnschrift: false,
        individuelleAnschrift: '',
        textbloecke: [],
        individuellerTextblock: '',
        anzahlung: 0,
        uploadTitel: '',
        angebotUnterschriftAuftragnehmer: '0',
      };
      // this.neuDokumentGetMoeglicheUhrzeiten();
      this.neuDokument.dokumentart = this.prop_settings.auftraegeDokumentarten[0].id;
      if (
        this.prop_kunden
          .filter((o) => o.kundennummer === this.editAuftrag.kundennummer).length > 0
      ) {
        this.neuDokument.moeglicheAnsprechpartner = this.prop_kunden
          .filter((o) => o.kundennummer === this.editAuftrag.kundennummer)[0].ansprechpartner;
        if (this.neuDokument.moeglicheAnsprechpartner.length > 0) {
          [this.neuDokument.ansprechpartner] = this.neuDokument.moeglicheAnsprechpartner;
          this.neuDokument.ansprechpartnerId = this.neuDokument.ansprechpartner.id;
        } else {
          this.neuDokument.ansprechpartner = {};
          this.neuDokument.ansprechpartnerId = 0;
          this.neuDokument.useIndividuelleAnschrift = true;
        }
        this.neuDokument.moeglicheAnschriften = this.prop_kunden
          .filter((o) => o.kundennummer === this.editAuftrag.kundennummer)[0].anschriften;
        if (this.neuDokument.moeglicheAnschriften.length > 0) {
          [this.neuDokument.anschrift] = this.neuDokument.moeglicheAnschriften;
          this.neuDokument.anschriftId = this.neuDokument.anschrift.id;
        } else {
          this.neuDokument.anschrift = {};
          this.neuDokument.anschriftId = 0;
          this.neuDokument.useIndividuelleAnschrift = true;
        }
      }
      const heute = new Date();
      const dd = String(heute.getDate()).padStart(2, '0');
      const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = heute.getFullYear();
      this.neuDokument.datum = `${yyyy}-${mm}-${dd}`;
      this.neuDokument.id_auftrag = this.editAuftrag.id;
      this.neuDokument.kundennummer = this.editAuftrag.kundennummer;
      this.neuDokument.leistungen = JSON.parse(JSON.stringify(this.editAuftrag.leistungen));
      this.neuDokument.auftragsdaten = JSON.parse(JSON.stringify(this.editAuftrag));
      this.neuDokumentGetMoeglicheUhrzeiten();
    },
    async trySaveNeuDokument() {
      // console.log(this.neuDokument);
      this.$store.commit('main/switchIsSaving', true);
      this.loading_saveDokument = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/400/410/neuDokument/`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dokument: JSON.stringify(this.neuDokument),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            if (response.neuDokument) {
              this.editAuftrag.dokumente.push(response.neuDokument);
              this.prop_auftrag.dokumente.push(response.neuDokument);
              this.sortDokumente();
            } else {
              console.log('Fehler beim Erstellen des Dokuments');
            }
            this.dialog_neuDokument = false;
            /* setTimeout(() => {
              this.tryDownloadDokument(
                response.lastCreatedDocument.path,
                response.lastCreatedDocument.name,
              );
            }, 500); */
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die Einstellungen konnten nicht gespeichert werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.loading_saveDokument = false;
        },
      });
    },
    async tryDelDokument(id, pfad) {
      this.$store.commit('main/switchIsSaving', true);
      if (this.editAuftrag.dokumente.filter((o) => o.id === id).length > 0) {
        this.editAuftrag.dokumente.filter((o) => o.id === id)[0].loadingDelete = true;
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/400/410/`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dataToSave: JSON.stringify({ type: 'auftragDelDokument', id, pfad }),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else if (this.editAuftrag.dokumente.filter((o) => o.id === id).length > 0) {
            this.editAuftrag.dokumente.splice(
              this.editAuftrag.dokumente.indexOf(
                this.editAuftrag.dokumente.filter((o) => o.id === id)[0],
              ),
              1,
            );
            this.prop_auftrag.dokumente.splice(
              this.prop_auftrag.dokumente.indexOf(
                this.prop_auftrag.dokumente.filter((o) => o.id === id)[0],
              ),
              1,
            );
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die Einstellungen konnten nicht gespeichert werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          if (this.editAuftrag.dokumente.filter((o) => o.id === id).length > 0) {
            this.editAuftrag.dokumente.filter((o) => o.id === id)[0].loadingDelete = false;
          }
        },
      });
    },
    async tryDownloadDokument(path, name, newTab) {
      this.$store.commit('main/switchIsSaving', true);
      if (this.editAuftrag.dokumente.filter((o) => o.pfad === path).length > 0) {
        this.editAuftrag.dokumente.filter((o) => o.pfad === path)[0].loadingDownload = true;
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file: path,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          if (newTab) {
            a.target = '_blank';
          } else {
            a.download = name;
          }
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht heruntergeladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          if (this.editAuftrag.dokumente.filter((o) => o.pfad === path).length > 0) {
            this.editAuftrag.dokumente.filter((o) => o.pfad === path)[0].loadingDownload = false;
          }
        },
      });
    },
  },
  created() {
    this.editAuftrag = JSON.parse(JSON.stringify(this.prop_auftrag));
    this.xLoaded = JSON.stringify(this.prop_auftrag);
    this.dialog_editAuftrag = true;
    // this.changedVertragBeginn();
    this.changedVertragEnde();
    this.sortDokumente();
    // this.resetNeuDokument();
  },
};
</script>
