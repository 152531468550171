<template>
    <v-dialog
    scrollable
    v-model="prop_editKind.show"
    transition="dialog-bottom-transition"
    max-width="600"
    style="z-index:100;"
    >
    <template v-slot:default="dialog">
        <v-card>
        <v-toolbar
            color="primary"
            dark
        >
          <span v-if="prop_editKind.kind.id > 0">
            {{ prop_editKind.kind.name }}
          </span>
          <span v-else>
            Neues Kind
          </span>
          <span
            v-if="prop_editKind.kind.name_klasse !== ''"
            class="ms-1"
          >
            ({{ prop_editKind.kind.name_klasse }})
          </span>
        </v-toolbar>
        <v-card-text class="pt-2">
          <v-form v-model="formValid">
            <v-row>
              <v-col class="d-flex justify-space-around flex-wrap">
                <!-- REGELAUSNAHMEN -->
                <v-dialog
                  scrollable
                  v-model="dialog_regelausnahmen"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                    >
                      Regelausnahmen
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <b>Regelausnahmen</b>
                      <v-select
                        label="Wochentag"
                        v-model="wochentag"
                        :items="wochentage"
                        item-text="name"
                        item-value="id"
                      />
                    </v-card-title>

                    <v-card-text>
                      <div
                        v-for="(regel, idx) in prop_editKind.kind.regelausnahmen"
                        :key="idx"
                      >
                        <div v-if="wochentag === regel.wochentag">
                          <v-row align="center">
                            <v-col>
                              <v-checkbox v-model="regel.anwesend"
                              label="anwesend" />
                            </v-col>
                            <v-col cols="2" align="center">
                              <v-btn
                                x-small
                                color="primary"
                                :disabled="!prop_editKind.allowEdit"
                                @click="delRegelausnahme(idx);"
                              >
                                <v-icon small>mdi-minus-box</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row align="center" v-if="regel.anwesend">
                            <v-col>
                              <TimePicker
                                label="Entlasszeit"
                                icon=""
                                v-model="regel.entlasszeit"
                                :disabled="!prop_editKind.allowEdit"
                              />
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Kommentar"
                                v-model="regel.kommentar"
                                maxlength="25"
                                :disabled="!prop_editKind.allowEdit"
                                :rules="[
                                  $store.state.main.inputValidationRules.name,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div align="center">
                        <v-btn
                          v-if="
                            prop_editKind.kind.regelausnahmen
                            .filter((o) => o.wochentag === wochentag)
                            .length <= 0
                            && prop_editKind.allowEdit
                          "
                          x-small
                          color="primary"
                          @click="addRegelausnahme();"
                        >
                          <v-icon left small>mdi-plus-box</v-icon>
                          Ausnahme hinzuf.
                        </v-btn>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog_regelausnahmen = false"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- REGELAUSNAHMEN -->
                <!-- ANWESENHEIT -->
                <v-dialog
                  scrollable
                  v-model="dialog_anwesenheit"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                    >
                      Anwesenheit
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <b>Anwesenheit Heute</b>
                    </v-card-title>

                    <v-card-text class="py-4">
                      <v-row align="center">
                        <v-col
                          class="py-0"
                          v-if="prop_editKind.kind.anwesenheitHeute.zeit_von !== ''"
                        >
                          <TimePicker
                            label="Von"
                            icon=""
                            :disabled="!prop_editKind.allowEdit"
                            v-model="prop_editKind.kind.anwesenheitHeute.zeit_von"
                          />
                        </v-col>
                        <v-col class="py-0">
                          <TimePicker
                            label="Bis"
                            icon=""
                            :disabled="true"
                            v-model="prop_editKind.kind.anwesenheitHeute.anwesendHeuteBis"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <v-text-field
                            v-if="
                              prop_editKind.kind.anwesenheitHeute.entlassgrund === 'Regelausnahme'
                            "
                            label="Entlassgrund: Regelausnahme"
                            v-model="prop_editKind.kind.anwesenheitHeute.entlassgrundRegelausnahme"
                            :disabled="true"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            label="Entlassgrund"
                            v-model="prop_editKind.kind.anwesenheitHeute.entlassgrund"
                            :disabled="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card
                            elevation="2"
                          >
                            <v-card-title>Ausnahmen</v-card-title>
                            <v-card-text>
                              <v-row class="mt-2">
                                <v-col class="py-0 pe-10">
                                  <DatePicker
                                    label="Datum"
                                    icon=""
                                    v-model="ausnahmenShowDatum"
                                  />
                                </v-col>
                              </v-row>
                              <div
                                v-if="
                                  (
                                    !ausnahmen
                                    .filter((o) => o.tag === ausnahmenShowDatum).length > 0
                                    || 1 === 1
                                  )
                                  && !ausnahmen
                                    .filter((o) => o.tag === ausnahmenShowDatum)
                                    .filter((o) => o.elternErstellt === false)
                                    .length > 0
                                "
                                align="center"
                              >
                                <v-btn
                                  x-small
                                  color="primary"
                                  :disabled="
                                    !prop_editKind.allowEdit
                                    ||
                                    (
                                      prop_editKind.kind.anwesenheitsstatus === 2
                                      && ausnahmenShowDatum === heute
                                    )
                                  "
                                  @click="addAusnahme();"
                                >
                                  <v-icon left small>mdi-plus-box</v-icon>
                                  Ausnahme hinzuf.
                                </v-btn>
                                <p
                                  v-if="
                                    (
                                      prop_editKind.kind.anwesenheitsstatus === 2
                                      && ausnahmenShowDatum === heute
                                    )
                                  "
                                >
                                  {{ prop_editKind.kind.vorname }} wurde für heute
                                  bereits an- und abgemeldet. Daher kann keine neue
                                  Ausnahme erstellt werden.<br>
                                  Um die Anwesenheit zu ändern, muss zuerst die
                                  Abmeldung rückgängig gemacht werden.
                                </p>
                              </div>
                              <div
                                v-for="(ausnahme, idx) in ausnahmen"
                                :key="idx"
                              >
                                <div
                                  v-if="ausnahme.tag === ausnahmenShowDatum"
                                  :class="{ elternErstellt: ausnahme.elternErstellt }"
                                >
                                  <v-row class="my-0 mt-4"
                                   v-if="ausnahme.elternErstellt"
                                  >
                                    <v-col class="py-0" style="color:red;">
                                      Die folgende Ausnahme wurde am
                                      {{ ausnahme.elternErstellt_tag }} um
                                      {{ ausnahme.elternErstellt_zeit }}
                                      durch einen Erziehungsberechtigten
                                      erstellt:
                                    </v-col>
                                  </v-row>
                                  <v-row class="my-0 mt-4"
                                   v-if="
                                    ausnahmen
                                    .filter((o) => o.tag === ausnahmenShowDatum)
                                    .filter((o) => o.elternErstellt === true)
                                    .length > 0
                                    && ausnahme.elternErstellt === false
                                   "
                                  >
                                    <v-col class="py-0" style="color:red;">
                                      Die folgende Ausnahme überschreibt die Ausnahme
                                      des Erziehungsberechtigten:
                                    </v-col>
                                  </v-row>
                                  <v-row class="my-0">
                                    <v-col class="py-0">
                                      <v-checkbox
                                        v-model="ausnahme.anwesend"
                                        label="Anwesend"
                                        :disabled="
                                          ausnahme.elternErstellt
                                          || !prop_editKind.allowEdit
                                        "
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="ausnahme.anwesend"
                                    class="my-0"
                                  >
                                    <v-col class="py-0">
                                      <TimePicker
                                        label="Entlasszeit"
                                        icon=""
                                        v-model="
                                          ausnahme.entlasszeit
                                        "
                                        :disabled="
                                          ausnahme.elternErstellt
                                          || !prop_editKind.allowEdit
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="my-0"
                                  >
                                    <v-col class="py-0 pe-10">
                                      <v-text-field
                                        clearable
                                        label="Grund"
                                        prepend-icon="mdi-pencil"
                                        v-model="ausnahme.kommentar"
                                        :rules="[
                                          $store.state.main.inputValidationRules.name,
                                        ]"
                                        :disabled="
                                          ausnahme.elternErstellt
                                          || !prop_editKind.allowEdit
                                        "
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row class="ma-0">
                                    <v-col>
                                      <span
                                        v-if="ausnahme.anhaenge.length === 1"
                                      >
                                        Anhang:
                                      </span>
                                      <span
                                        v-if="ausnahme.anhaenge.length > 1"
                                      >
                                        Anhänge:
                                      </span>
                                      <v-btn
                                        x-small
                                        v-for="(anh, idx) in ausnahme.anhaenge"
                                        :key="idx"
                                        @click="tryDownloadDokument(anh.pfad, anh.name);"
                                        max-width="100%"
                                        style="overflow:hidden;"
                                      >
                                        {{ anh.name }}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="my-0"
                                  >
                                    <v-col class="py-0 pe-10" align="right">
                                      <v-btn
                                        x-small
                                        color="primary"
                                        @click="delAusnahme(idx);"
                                        :disabled="
                                          ausnahme.elternErstellt
                                          || !prop_editKind.allowEdit
                                        "
                                      >
                                        <v-icon small left>mdi-minus-box</v-icon>
                                        Ausnahme löschen
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog_anwesenheit = false"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ANWESENHEIT -->
                <!-- FAMILIE -->
                <v-dialog
                  v-model="dialog_familie"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                    >
                      Familie
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <b>Familie</b>
                    </v-card-title>

                    <v-card-text>
                      <v-btn
                        x-small
                        v-if="
                          $store.state.main.save.user.rechte.includes('x|200|100')
                        "
                        @click="$router.push(`/200-100?e=${prop_editKind.kind.id_eltern}`)"
                        :disabled="!prop_elternkonto.id"
                      >
                        <v-icon small left>mdi-open-in-new</v-icon>
                        Elternkonto
                      </v-btn><br><br>
                      <v-autocomplete
                        multiple chips
                        label="Geschwister"
                        :items="prop_kinder.filter((o) => o.id !== prop_editKind.kind.id)"
                        v-model="geschwister"
                        item-text="nameMitKlasse"
                        item-value="id"
                        no-data-text="Keine Kinder verfügbar"
                        :disabled="!prop_editKind.allowEdit"
                      >
                        <template v-slot:selection="data">
                          <span
                            class="grey--text text-caption"
                          >
                            <v-chip x-small
                              close
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              @click="data.select"
                              @click:close="removeGeschwist(data.item)"
                            >{{ data.item.nameMitKlasse }}</v-chip>
                          </span>
                        </template>
                      </v-autocomplete>
                      <b>Telefonnummern:</b>
                      <div
                        v-for="(nummer, idx) in prop_editKind.kind.telefonnummern"
                        :key="idx"
                      >
                        <v-row
                          v-if="nummer.id === '0'"
                          align="center"
                          class="mt-4 pa-2 grey lighten-5"
                        >
                          <v-col cols="12" class="py-0 pt-4">
                            <v-text-field
                              label="Name"
                              v-model="nummer.name"
                              :rules="[
                                $store.state.main.inputValidationRules.required,
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="8" class="py-0">
                            <v-text-field
                              label="Nummer"
                              v-model="nummer.nummer"
                              :rules="[
                                $store.state.main.inputValidationRules.required,
                                $store.state.main.inputValidationRules.telefon,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" class="d-flex justify-space-between">
                            <a :href="`tel:${nummer.nummer}`" style="text-decoration:none;">
                              <v-btn x-small>
                                <v-icon small left>mdi-open-in-new</v-icon>
                                anrufen
                              </v-btn>
                            </a>
                          </v-col>
                        </v-row>
                        <v-row
                          v-else
                          align="center"
                          class="mt-4"
                        >
                          <v-col cols="12" class="py-0 pt-4">
                            <b>{{ nummer.name }}</b>
                          </v-col>
                          <v-col cols="8" class="py-0">
                            {{ nummer.nummer }}
                          </v-col>
                          <v-col cols="4" class="d-flex justify-space-between">
                            <a :href="`tel:${nummer.nummer}`" style="text-decoration:none;">
                              <v-btn x-small>
                                <v-icon small left>mdi-open-in-new</v-icon>
                                anrufen
                              </v-btn>
                            </a>
                          </v-col>
                        </v-row>
                      </div>
                      <div align="center" class="py-2 mt-4">
                        <v-btn x-small
                          @click="delTelefonnummer(prop_editKind.kind.telefonnummern.length - 1);"
                          class="mx-2"
                          v-if="
                            prop_editKind.kind.telefonnummern
                          "
                          :disabled="
                            prop_editKind.kind.telefonnummern
                              .filter((o) => o.id === '0').length <= 0
                          "
                        >
                          <v-icon small>mdi-minus-box</v-icon>
                        </v-btn>
                        <v-btn x-small
                          @click="addTelefonnummer();"
                          class="mx-2"
                        >
                          <v-icon small>mdi-plus-box</v-icon>
                        </v-btn>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog_familie = false"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- FAMILIE -->
                <!-- DOKUMENTE -->
                <F200KinderDokumente
                  v-if="prop_editKind.id > 0"
                  :idkind="prop_editKind.id"
                />
                <!-- DOKUMENTE -->
              </v-col>
            </v-row>
            <v-row class="mb-0">
              <v-col class="pb-0">
                <v-text-field
                  label="Vorname"
                  prepend-icon="mdi-account"
                  v-model="prop_editKind.kind.vorname"
                  :rules="[
                    $store.state.main.inputValidationRules.required,
                    $store.state.main.inputValidationRules.name,
                  ]"
                  :disabled="!prop_editKind.allowEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col class="py-0">
                <v-text-field
                  label="Nachname"
                  prepend-icon="mdi-account"
                  v-model="prop_editKind.kind.nachname"
                  :rules="[
                    $store.state.main.inputValidationRules.required,
                    $store.state.main.inputValidationRules.name,
                  ]"
                  :disabled="!prop_editKind.allowEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col class="py-0">
                <v-select
                  label="Geschlecht"
                  :items="select_geschlecht"
                  v-model="prop_editKind.kind.junge"
                  item-text="name"
                  item-value="id"
                  :disabled="!prop_editKind.allowEdit"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col class="py-0">
                <v-select
                  label="Klasse"
                  :items="prop_klassen"
                  v-model="prop_editKind.kind.id_klasse"
                  item-text="name"
                  item-value="id"
                  :disabled="!prop_editKind.allowEdit"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col class="py-0">
                <TimePicker
                  label="Betreuungsplatz"
                  icon="mdi-clock-time-four"
                  v-model="prop_editKind.kind.betreuungsplatz"
                  :disabled="!prop_editKind.allowEdit"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col class="py-0">
                <TimePicker
                  label="Standardentlasszeit"
                  icon="mdi-clock-time-four"
                  v-model="prop_editKind.kind.standardentlasszeit"
                  :disabled="!prop_editKind.allowEdit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-space-between">
            <v-btn
            small
            text
            @click="dialog.value = false"
            >Schließen</v-btn>
            <v-dialog
              v-if="
                prop_editKind.kind.id > 0
                && prop_editKind.allowEdit
                && $store.state.main.save.user.rechte.includes('x|200|000|010')
              "
              v-model="dialog_delKind"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  :loading="deleting"
                  >Löschen</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Sicher?
                </v-card-title>

                <v-card-text class="pt-4">
                  Es werden sämtliche Daten gelöscht.
                  Anwesenheiten bleiben erhalten.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog_delKind = false;"
                  >
                    Nichts tun
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="
                      dialog_delKind = false;
                      deleting = true;
                      $emit('delKind', { kind: prop_editKind.kind, success: 'kindUpdated' });
                    "
                  >
                    löschen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
            v-if="
              prop_editKind.allowEdit
            "
            small
            :disabled="!formValid || prop_editKind.kind.id_klasse == 0"
            text
            color="primary"
            :loading="saving"
            @click="
              saving = true;
              $emit('editKind', {
                kind: prop_editKind.kind,
                id_eltern: prop_editKind.kind.id_eltern,
                ausnahmen: ausnahmen,
                telefonnummern: prop_editKind.kind.telefonnummern,
                geschwister: geschwister,
                success: 'kindUpdated'
              });
            "
            >Speichern</v-btn>
        </v-card-actions>
        </v-card>
    </template>
    </v-dialog>
</template>

<script>
import $ from 'jquery';
import TimePicker from '@/components/Inputs/TimePicker.vue';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import F200KinderDokumente from '@/components/Funktionen/200/200/F200KinderDokumenteKarte.vue';

export default {
  components: {
    TimePicker,
    DatePicker,
    F200KinderDokumente,
  },
  props: {
    prop_kinder: {
      type: Array,
      default() { return []; },
    },
    prop_elternkonto: {
      type: Object,
      default() { return {}; },
    },
    prop_editKind: {
      type: Object,
      default() { return {}; },
    },
    prop_klassen: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    dialog_regelausnahmen: false,
    dialog_anwesenheit: false,
    dialog_familie: false,
    dialog_delKind: false,
    formValid: null,
    saving: false,
    deleting: false,
    geschwister: [],
    ausnahmen: [],
    ausnahmenShowDatum: '',
    select_geschlecht: [
      { id: true, name: 'Junge' },
      { id: false, name: 'Mädchen' },
    ],
    heute: '',
    wochentag: '',
    wochentage: [
      { id: '1', name: 'Montags', nameUnv: 'Montags' },
      { id: '2', name: 'Dienstags', nameUnv: 'Dienstags' },
      { id: '3', name: 'Mittwochs', nameUnv: 'Mittwochs' },
      { id: '4', name: 'Donnerstags', nameUnv: 'Donnerstags' },
      { id: '5', name: 'Freitags', nameUnv: 'Freitags' },
      { id: '6', name: 'Samstags', nameUnv: 'Samstags' },
      { id: '7', name: 'Sonntags', nameUnv: 'Sonntags' },
    ],
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    isSaving(neu) {
      if (neu === false) {
        this.saving = neu;
        this.deleting = neu;
      }
    },
  },
  methods: {
    addTelefonnummer() {
      this.prop_editKind.kind.telefonnummern.push({
        id: '0',
        name: '',
        nummer: '',
      });
    },
    delTelefonnummer(idx) {
      if (this.prop_editKind.kind.telefonnummern[idx].id === '0') {
        this.prop_editKind.kind.telefonnummern.splice(idx, 1);
      }
    },
    removeGeschwist(delKind) {
      // const index = this.prop_editKind.kind.geschwister.indexOf(delKind);
      // this.prop_editKind.kind.geschwister.splice(index, 1);
      const index = this.geschwister.indexOf(delKind);
      this.geschwister.splice(index, 1);
    },
    addAusnahme() {
      this.ausnahmen.push({
        id: 0,
        elternErstellt: false,
        elternErstellt_zeit: '',
        tag: this.ausnahmenShowDatum,
        anwesend: false,
        entlasszeit: this.prop_editKind.kind.standardentlasszeit,
        kommentar: 'geht nach Schulschluss',
        anhaenge: [],
      });
    },
    delAusnahme(idx) {
      this.ausnahmen.splice(idx, 1);
    },
    addRegelausnahme() {
      this.prop_editKind.kind.regelausnahmen.push({
        id: 0,
        entlasszeit: '00:00',
        wochentag: this.wochentag,
        kommentar: '',
        anwesend: true,
      });
      let anzahl;
      this.wochentage.forEach((item) => {
        anzahl = this.prop_editKind.kind.regelausnahmen.filter(
          (o) => o.wochentag === item.id,
        ).length;
        item.name = `${item.nameUnv} (${anzahl})`;
      });
    },
    delRegelausnahme(idx) {
      this.prop_editKind.kind.regelausnahmen.splice(idx, 1);
      let anzahl;
      this.wochentage.forEach((item) => {
        anzahl = this.prop_editKind.kind.regelausnahmen.filter(
          (o) => o.wochentag === item.id,
        ).length;
        item.name = `${item.nameUnv} (${anzahl})`;
      });
    },
    async tryDownloadDokument(path, name) {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file: path,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = name;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht heruntergeladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    const heute = new Date();
    const dd = String(heute.getDate()).padStart(2, '0');
    const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = heute.getFullYear();
    this.heute = `${yyyy}-${mm}-${dd}`;
    let weekday = heute.getDay();
    if (weekday <= 0) {
      weekday = 7;
    }
    this.wochentag = `${weekday}`;
    let anzahl;
    this.wochentage.forEach((item) => {
      anzahl = this.prop_editKind.kind.regelausnahmen.filter(
        (o) => o.wochentag === item.id,
      ).length;
      item.name = `${item.nameUnv} (${anzahl})`;
    });
    this.ausnahmenShowDatum = `${yyyy}-${mm}-${dd}`;
    this.ausnahmen = this.prop_editKind.kind.ausnahmen;
    this.geschwister = this.prop_editKind.kind.geschwister;
  },
};
</script>
