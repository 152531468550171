import $ from 'jquery';

export default {
  namespaced: true,
  state: {
    loginLoading: false,
    session: {
      code: '', // Identifiziert die aktive Loginsitzung
      lastView: '', // Wird dynamisch bei Login und getSettings geladen
      subscription: {},
    },
  },
  getters: {
  },
  mutations: {
    setLoginLoading(state, event) {
      state.loginLoading = event;
    },
    setSession(state, { code, lastView, subscription }) {
      if (code) {
        state.session.code = code;
      }
      if (lastView) {
        state.session.lastView = lastView;
      }
      if (subscription) {
        state.session.subscription = subscription;
      }
      localStorage.setItem('login_session', JSON.stringify(state.session));
    },
    setSessionLastView(state, event) {
      state.session.lastView = event;
    },
    setUser(state, event) {
      state.session.user = event;
    },
    setUserIdent(state, event) {
      state.session.user.ident = event;
    },
    login(state, { router }) {
      if (this.state.main.save.mainmenu_links.some((e) => e.route === state.session.lastView)) {
        // Wenn zu navigierende Seite unter den erlaubten Seiten ist:
      } else if (this.state.main.save.mainmenu_links.length > 0) {
        // Wenn zu navigierende Seite NICHT unter den erlaubten Seiten ist:
        // leite zur ersten erlaubten Seite um
        state.session.lastView = this.state.main.save.mainmenu_links[0].route;
      } else {
        // Wenn keine erlaubte Seite vorhanden ist:
        console.log('Keine Rechte vorhanden!');
        this.commit('login/logout', { router });
      }
      if (state.session.lastView === '-') {
        // Wenn neu eingeloggt wurde (kein Relogin),
        // leite zur ersten erlaubten Seite um
        state.session.lastView = this.state.main.save.mainmenu_links[0].route;
      }
      if (router.history.current.path !== state.session.lastView) {
        router.push({ path: state.session.lastView });
      }
      this.commit('login/saveSession');
      this.commit('main/mainmenu_switchNav', true);
      this.commit('login/setLoginLoading', false);
      // Begrüßung:
      /* this.commit('main/alert_showAlert', {
        text: `Willkommen ${state.session.user.ident}`,
      }, { root: true }); */
      /*
        Wenn der Nutzer in einer Gruppe ist, die gezwungen ist
        das Standardpasswort durch ein eigenes Passwort zu ersetzen,
        leite auf die PW-Reset Seite um und zeige eine Meldung an.
        (Sofern er das Recht besitzt sein Passwort u ändern.)
      */
      // console.log(this.state);
      if (this.state.main.save.nutzergruppen.filter((o) => {
        if (
          this.state.main.save.user.gruppen.includes(o.id)
          && o.forcePwReset
          && this.state.main.save.user.rechte.includes('x|010')
          && this.state.main.save.user.rootPW !== ''
        ) {
          return o;
        }
        return null;
      }).length > 0) {
        // router.push({ path: '/010-010' });
        setTimeout(() => {
          this.commit('main/alert', {
            typ: '',
            text: `<b>Willkommen ${this.state.main.save.user.ident}!</b><br>Aus Sicherheitsgründen,
            wird dringend empfohlen das Startpasswort durch ein sicheres eigenes Passwort
            zu ersetzen. <a href="/010-010"><input type="button" value="Passwort jetzt ändern"></a>`,
            timeout: 100000,
          }, { root: true });
        }, 1000);
      }
    },
    logout(state, event) {
      const loginSession = JSON.parse(localStorage.getItem('login_session'));
      let subscription = '';
      if (loginSession.subscription) {
        subscription = loginSession.subscription;
      }
      state.session = {};
      this.commit('login/setLoginLoading', false);
      this.commit('main/mainmenu_switchNav', false);
      if (event.router.history.current.path !== '/') {
        event.router.push({ path: '/' });
      }
      console.log(JSON.stringify(subscription));
      localStorage.clear();
      if (subscription !== '') {
        localStorage.setItem('login_session', JSON.stringify({ subscription }));
      }
    },
    saveSession(state) {
      localStorage.setItem('login_session', JSON.stringify(state.session));
    },
    backendErrorSession() {
      // Wenn vom Backend-Server eine ungültige Sitzung erkannt wird,
      // wird diese Funktion aufgerufen
      localStorage.clear();
      window.location.reload();
    },
  },
  actions: {
    async tryLogin({ commit, dispatch }, { session, router }) {
      if (!session && router.history.current.path !== '/') {
        // Wenn keine aktive Sitzung, leite zur Loginseite
        router.push({ path: '/' });
      } else {
        // Lade Nutzerdaten
        let nn = '';
        let pw = '';
        if (session.user) {
          nn = session.user.nn;
          pw = session.user.pw;
        }
        commit('setLoginLoading', true);
        let subscription = '{}';
        if (localStorage.getItem('login_session')) {
          const loginSession = JSON.parse(localStorage.getItem('login_session'));
          if (!loginSession.subscription) {
            loginSession.subscription = '{}';
          }
          subscription = JSON.stringify(loginSession.subscription);
        }
        $.ajax({
          url: `${process.env.VUE_APP_API_HOST}/login/?func=login`,
          type: 'post',
          data: {
            code: session.code,
            nn,
            pw,
            subscription,
          },
          success: (response) => {
            // console.log(response);
            let lastView = '-';
            if (session.lastView) {
              lastView = session.lastView;
            }
            commit('setSession', { code: response.code, lastView, subscription: JSON.parse(subscription) });
            switch (response.status) {
              case 'success':
                dispatch('main/tryGetSettings', { code: response.code, login: true, router }, { root: true });
                break;
              case 'errorCountMax':
                commit('main/alert_showAlert', {
                  text: 'Zu viele Loginversuche. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                }, { root: true });
                if (router.history.current.path !== '/') {
                  router.push({ path: '/' });
                }
                localStorage.clear();
                commit('setLoginLoading', false);
                break;
              default:
                commit('main/alert_showAlert', { text: 'Logindaten ungültig.' }, { root: true });
                if (router.history.current.path !== '/') {
                  router.push({ path: '/' });
                }
                localStorage.clear();
                commit('setLoginLoading', false);
            }
          },
          error: () => {
            // Wenn keine Verbindung zum Server aufgebaut werden konnte
            if (session.code) {
              // Wenn aktive Sitzung lokal gespeichert wurde,
              // führe offline-login durch
              // commit('login', { session, router });
            } else {
              commit('main/alert_showAlert', { text: 'Bitte Netzwerkverbindung überprüfen' }, { root: true });
            }
            commit('setLoginLoading', false);
          },
          timeout: 10000,
        });
      }
    },
    async tryLogout({ commit, rootState }, payload) {
      commit('setLoginLoading', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/login/?func=logout`,
        type: 'post',
        data: {
          code: rootState.login.session.code,
        },
        complete: () => {
          commit('setLoginLoading', false);
        },
        timeout: 10000,
      });
      commit('logout', payload);
    },
  },
};
