<template>
  <div
  v-if="allowPush"
  style="
    position:absolute;left:0px;bottom:0px;z-index:100;
    pointer-events:auto;display:none;
  ">
    <v-btn
      @click="showLocalNotification('Du hast eine Nachricht!', {
      body: 'Lorem ipsum, dolor sit amet...',
      actions: [
        { action: '/410', title: 'Kundenliste' },
      ],
    });">push</v-btn>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    status: '--',
  }),
  computed: {
    allowPush() {
      return this.$store.state.f020.pushNotification;
    },
  },
  watch: {
    allowPush(neu) {
      if (neu === true) {
        console.log('push!');
        this.main();
      }
    },
  },
  methods: {
    showLocalNotification(title, options) {
      if ('serviceWorker' in navigator) {
        const notificationBody = {
          /* actions: [
            { action: '/410', title: 'Test' },
          ], */
          body: '',
          dir: 'auto', // auto | ltf | rtl
          icon: 'img/icons/android-chrome-512x512.png',
          // image: 'img/login/logo.png',
          vibrate: [
            200, 100, 200, 100, 200, 100, 200,
            100, 200, 100, 200, 100, 200, 100, 200, 100, 200,
          ],
          tag: 'standard', // ID der Info
          // timestamp: 0 // unix timestamp, wann soll angezeigt werden?
        };
        if (Object.keys(options).length > 0) {
          Object.keys(options).forEach((k) => {
            notificationBody[k] = options[k];
          });
        }
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(title, notificationBody);
        });
      }
    },
    check() {
      if (!('serviceWorker' in navigator)) {
        throw new Error('No Service Worker support!');
      }
      if (!('PushManager' in window)) {
        throw new Error('No Push API Support!');
      }
    },
    async requestNotificationPermission() {
      const permission = await window.Notification.requestPermission();
      // value of permission can be 'granted', 'default', 'denied'
      // granted: user has accepted the request
      // default: user has dismissed the notification permission popup by clicking on x
      // denied: user has denied the request.
      if (permission !== 'granted') {
        throw new Error('Permission not granted for Notification');
      }
      this.status = Notification.permission;
    },
    main() {
      this.check();
      this.requestNotificationPermission();
      if (this.status !== '--') {
        this.subscribeNotifications();
      }
    },
    urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; i += 1) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    async subscribeNotifications() {
      try {
        await navigator.serviceWorker.ready.then((registration) => {
          this.tryGetSubstription(registration);
        });
      } catch (err) {
        console.log('Error', err);
      }
    },
    async tryGetSubstription(registration) {
      const applicationServerKey = this.urlB64ToUint8Array(
        'BGEXB2r9DRNjZ2rQWL3yKEpjPm-EIBXP-lNVE-D5cLOjmiWjJiOKpSv_MqJd0tXc7eH0OVyzgCwWZFoLOeCUMoU',
      );
      const options = { applicationServerKey, userVisibleOnly: true };
      const subscription = await registration.pushManager
        .subscribe(options);
      // console.log(JSON.stringify(subscription));
      this.trySaveSubscription(subscription);
    },
    async trySaveSubscription(subscription) {
      let loginSession;
      if (localStorage.getItem('login_session')) {
        loginSession = JSON.parse(localStorage.getItem('login_session'));
        loginSession.subscription = subscription;
      } else {
        loginSession = { subscription };
      }
      localStorage.setItem('login_session', JSON.stringify(loginSession));
      this.$store.commit('login/setSession', { subscription });
      if (this.$store.state.login.session.code) {
        const body = new FormData();
        body.append('code', this.$store.state.login.session.code);
        body.append('subscription', JSON.stringify(subscription));
        await fetch(`${process.env.VUE_APP_API_HOST}/login/?func=saveSubscription`, {
          method: 'POST',
          body,
        })
          .then((res) => res.json())
          .then(/* (response) => console.log(response); */)
          .catch((error) => console.log(error));
      }
    },
  },
  created() {
    // this.$store.commit('f020/load'); // Lade lokale Einstellungen des Nutzers
    if (this.allowPush) {
      this.main();
    }
  },
};
</script>
