import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Profil from '../views/Profil.vue';

import Appeinstellungen from '@/views/Appeinstellungen.vue';
import Benutzerverwaltung from '@/views/Benutzerverwaltung.vue';
import V020Einstellungen from '@/views/V020Einstellungen.vue';

import Mitglieder from '@/views/Funktionen/100/Mitglieder.vue';
import V110Kalender from '@/views/Funktionen/100/V110Kalender.vue';
import Nachrichten from '@/views/Funktionen/100/Nachrichten.vue';
import V150Anwesenheiten from '@/views/Funktionen/100/V150Anwesenheiten.vue';

import Kinder from '@/views/Funktionen/200/Kinder.vue';
import V200Kinderverwaltung from '@/views/Funktionen/200/V200Kinderverwaltung.vue';
import ElternKontakt from '@/views/Funktionen/200/ElternKontakt.vue';

import Hydrantenpflege from '@/views/Funktionen/300/Hydrantenpflege.vue';

import Kunden from '@/views/Funktionen/400/Kunden.vue';
import V420Mitarbeiter from '@/views/Funktionen/400/V420Mitarbeiter.vue';

import V999Appinfo from '@/views/V999Appinfo.vue';

import Template from '@/views/Template.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/000*',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/001*',
    name: 'Appeinstellungen',
    component: Appeinstellungen,
  },
  {
    path: '/005*',
    name: 'Benutzerverwaltung',
    component: Benutzerverwaltung,
  },
  {
    path: '/010*',
    name: 'Profil',
    component: Profil,
  },
  {
    path: '/020*',
    name: 'Einstellungen',
    component: V020Einstellungen,
  },
  {
    path: '/100*',
    name: 'Mitglieder',
    component: Mitglieder,
  },
  {
    path: '/110*',
    name: 'Kalender',
    component: V110Kalender,
  },
  {
    path: '/120*',
    name: 'Nachrichten',
    component: Nachrichten,
  },
  {
    path: '/150*',
    name: 'Anwesenheiten',
    component: V150Anwesenheiten,
  },
  {
    path: '/200neu*',
    name: 'Kinderverwaltung',
    component: V200Kinderverwaltung,
  },
  {
    path: '/200*',
    name: 'Kinder',
    component: Kinder,
  },
  {
    path: '/210*',
    name: 'ElternKontakt',
    component: ElternKontakt,
  },
  {
    path: '/310*',
    name: 'Hydrantenpflege',
    component: Hydrantenpflege,
  },
  {
    path: '/410*',
    name: 'Kunden',
    component: Kunden,
  },
  {
    path: '/420*',
    name: 'Mitarbeiter',
    component: V420Mitarbeiter,
  },
  {
    path: '/999*',
    name: 'Appinfo',
    component: V999Appinfo,
  },
  {
    path: '*',
    name: '404',
    component: Template,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Store.commit('main/routerUpdateCurrentFeature', to.path.substring(1, 4));

  if (Store.state.main.save.settings) {
    document.title = Store.state.main.save.settings.app_titel;
  }

  if (from.path !== '/') {
    // Wenn zu einer anderen Seite gewechselt wird,
    // speichere die letzte Seite in lastView
    Store.commit('login/setSessionLastView', to.path);
    Store.commit('login/saveSession');

    if (to.path !== '/') {
      // Wenn nicht login oder logout,
      // lade die globalen App-settings
      let code = JSON.parse(localStorage.getItem('login_session'));
      if (code) {
        code = code.code;
        Store.commit('main/saveIsChanged', false);
        Store.dispatch('main/tryGetSettings', { code });
      }
    }
  }
});

export default router;
