<template>
  <v-dialog
    v-model="dialogShow"
    width="500"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        class="ma-1"
      >
        Karte
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <b>Karte</b>
      </v-card-title>

      <v-card-text>
        <v-container v-if="!showDokument">
          <v-select
            label="Design"
            v-model="value.design"
            :items="designs"
            item-text="name"
            item-value="id"
          />
          <v-row>
            <v-col>
              <v-checkbox
                label="Gesicht erkennen"
                v-model="value.gesichtauto"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Faktor (%)"
                v-model="value.faktor"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Gesicht horizontal verschieben"
                v-model="value.offsetx"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Gesicht vertikal verschieben"
                v-model="value.offsety"
              />
            </v-col>
          </v-row>
          <v-file-input
            ref="foto"
            label="Foto"
            v-model="value.foto"
            dense
            prepend-icon="mdi-camera"
            accept="image/jpeg"
          ></v-file-input>
          <div align="center">
            <v-btn
              v-if="thereIsDokument"
              small
              class="ma-2"
              @click="showDokument = true"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="primary"
              small
              class="ma-2"
              @click="tryCreateIDCard('createKarte');"
              :loading="$store.state.f200.loading.createKarte"
            >
              Erstellen
            </v-btn>
          </div>
        </v-container>
        <div v-if="showDokument" align="center">
          <div align="center" v-html="previewHtml"></div>
          <v-menu
            bottom
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showDokument = false">
                <v-list-item-title>Neue Karte</v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog_delKarte = true">
                <v-list-item-title>Karte Löschen</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog
            v-model="dialog_delKarte"
            width="500"
          >
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
              >
                bearbeiten
              </v-btn>
            </template> -->

            <v-card>
              <v-card-title>
                Sicher?
              </v-card-title>

              <v-card-text>
                Soll die vorhandene Karte
                vollständig gelöscht werden?
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  small text
                  class="ma-2"
                  @click="dialog_delKarte = false"
                >
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  small text
                  color="error"
                  class="ma-2"
                  @click="tryCreateIDCard('deleteKarte');"
                  :loading="$store.state.f200.loading.deleteKarte"
                >
                  Löschen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn small class="ma-2" @click="
            $store.dispatch('main/tryDownload',
              {
                path: `f200-200/kinderDokumente/${value.id_kind}/IDCard/IDCard.pdf`,
                name: `${kind.nachname}_${kind.vorname}_Karte.pdf`,
                newTab: true,
              },
            );
          "
          color="primary">PDF Download</v-btn>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialogShow = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },
  props: {
    idkind: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    dialogShow: false,
    dialog_delKarte: false,
    designs: [],
    kind: {},
    value: {
      foto: null,
      design: null,
      id_kind: '0',
      gesichtauto: true,
      offsetx: 0,
      offsety: 0,
      faktor: 80,
    },
    thereIsDokument: false,
    showDokument: false,
    previewHtml: '',
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    getData() {
      this.designs = JSON.parse(JSON.stringify(
        this.$store.state.f200.store.settings.IDCard_designs,
      ));
      this.kind = JSON.parse(JSON.stringify(
        this.$store.state.f200.store.kinder.filter((o) => o.id === this.idkind)[0],
      ));
      if (this.designs.filter((o) => o.id === this.kind.name_klasse).length > 0) {
        this.value.design = this.kind.name_klasse;
      } else {
        this.value.design = this.designs[0].id;
      }
      this.value.id_kind = this.kind.id;
      this.thereIsDokument = false;
      this.showDokument = false;
      if (this.kind.dokumente.filter((o) => o.id === 'IDCard').length > 0) {
        // Wenn ein Dokument vorhanden ist
        this.thereIsDokument = true;
        this.showDokument = true;
        // console.log(this.kind.dokumente.filter((o) => o.id === 'IDCard')[0]);
        const { preview } = this.kind.dokumente.filter((o) => o.id === 'IDCard')[0];
        // console.log(preview);
        this.tryGetPreview(`${preview}`);
      }
    },
    async tryCreateIDCard(func) {
      const fd = new FormData();
      fd.append('code', this.$store.state.login.session.code);
      fd.append('foto', this.value.foto);
      fd.append('design', this.value.design);
      fd.append('id_kind', this.value.id_kind);
      fd.append('gesichtauto', this.value.gesichtauto);
      fd.append('offsetx', this.value.offsetx);
      fd.append('offsety', this.value.offsety);
      fd.append('faktor', this.value.faktor);
      if (
        func === 'createKarte'
        && !this.value.foto
      ) {
        this.$store.commit('main/alert', {
          typ: 'error',
          text: 'Bitte zuerst ein Foto zum Hochladen auswählen.',
        }, { root: true });
      } else {
        this.$store.commit('f200/setLoading', { name: func, value: true });
        await fetch(`${process.env.VUE_APP_API_HOST}/200/200/createDocument/?${func}`, {
          method: 'POST',
          body: fd,
        })
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            if (
              func === 'createKarte'
              && response.status === 'success'
            ) {
              const time = new Date().getTime();
              let html = '';
              if (response.gesichtErkannt !== 'success') {
                html += '<p style="color:red;">Es wurde kein Gesicht erkannt.</p>';
              }
              html += `
                <img
                  src="${process.env.VUE_APP_API_HOST}/200/200/createDocument/${response.created.ergebnis}?t=${time}"
                  width="250px"
                  align="center"
                >
              `;
              this.previewHtml = html;
              this.showDokument = true;
              this.thereIsDokument = true;
              this.$store.commit('f200/deleteKarte', this.kind.id);
              this.$store.commit('f200/addKarte', {
                idKind: this.kind.id,
                karte: response.neuIDCard,
              });
            } else if (func === 'deleteKarte') {
              this.$store.commit('f200/deleteKarte', this.kind.id);
              this.getData();
              this.showDokument = false;
              this.thereIsDokument = false;
            }
            this.$store.commit('f200/setLoading', { name: func, value: false });
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit('f200/setLoading', { name: func, value: false });
          });
      }
    },
    async tryGetPreview(path) {
      let url = '';
      const senddata = {
        code: this.$store.state.login.session.code,
        file: path, // z.B. f200-200/kinderDokumente/IDCard_32.pdf
      };
      await fetch(`${process.env.VUE_APP_API_HOST}/data/download.php`, {
        method: 'POST',
        body: JSON.stringify(senddata),
      })
        .then((response) => response.blob())
        .then((blob) => {
          url = window.URL.createObjectURL(blob);
          /* const a = document.createElement('a');
          a.href = url;
          if (newTab) {
            a.target = '_blank';
          } else {
            a.download = name;
          }
          document.body.appendChild(a);
          a.click();
          a.remove(); */
        })
        .catch((error) => {
          // Wenn bei der Verbindung ein Fehler aufgetreten ist:
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die angeforderte Datei konnte nicht erzeugt werden.
            Bitte die Netzwerkverbindung prüfen.`,
          });
          console.log(error);
        });
      const html = `
        <img
          src="${url}"
          width="250px"
          align="center"
        >
      `;
      this.previewHtml = html;
    },
  },
  created() {
    this.getData();
  },
};
</script>
