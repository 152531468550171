<template>
  <div>
    <v-container>
      <p class="text-h4">Allgemein</p>
      <v-row>
        <v-col>
          <p class="text-h5">Funktionen</p>
          <div class="px-4">
            <v-checkbox
              v-model="pushNotification"
              label="Push Benachrichtigungen"
            />
            <p>
              Wenn Push Benachrichtigungen aktiviert sind, können wichtige Informationen
              angezeigt werden, auch wenn die App geschlossen ist.
            </p>
          </div>
        </v-col>
      </v-row>
      <div align="center">
        <v-btn
          small
          @click="speichern();"
        >Speichern</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    set: false,
    pushNotification: false,
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    speichern() {
      this.$store.commit('f020/changeState', { name: 'pushNotification', value: this.pushNotification });
    },
  },
  created() {
    this.pushNotification = this.$store.state.f020.pushNotification;
  },
};
</script>
