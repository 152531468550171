<template>
  <div>
    <v-dialog
      v-model="dialogDelMitarbeiter"
      width="500"
    >
      <v-card>
        <v-card-title>
          Wirklich löschen?
        </v-card-title>

        <v-card-text>
          Es werden alle Lohnabrechnungen etc. gelöscht.<br/>
          Die gelöschten Daten können nicht wieder hergestellt werden.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            text
            @click="dialogDelMitarbeiter = false"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="delMitarbeiter();"
            :loading="$store.state.f420.loading.delMitarbeiter"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      scrollable
      transition="dialog-bottom-transition"
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary darken-1"
        >
          <v-btn
            icon
            dark
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="idMitarbeiter > 0">
              {{ mitarbeiter.name }}
            </span>
            <span v-else>
              Neuer Mitarbeiter
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn
              dark
              text
              @click="showDialog = false"
            >
              Speichern
            </v-btn> -->
            <v-btn
              dark
              text
              @click="dialogDelMitarbeiter = true;"
              v-if="idMitarbeiter > 0"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <v-card-title>
          Lohnabrechnung
        </v-card-title> -->

        <v-card-text
          class="pa-0"
          style="height:100%;overflow-x:hidden;"
        >
          <v-form v-model="formvalid">
          <v-container fluid class="pa-4">
            <v-text-field
              label="Personalnummer"
              v-model="mitarbeiter.personalnummer"
              type="number"
            />
            <v-text-field
              label="Vorname"
              v-model="mitarbeiter.vorname"
              :rules="[
                $store.state.main.inputValidationRules.required,
              ]"
            />
            <v-text-field
              label="Nachname"
              v-model="mitarbeiter.nachname"
              :rules="[
                $store.state.main.inputValidationRules.required,
              ]"
            />
            <DatePicker
              label="Eintrittsdatum"
              v-model="mitarbeiter.eintrittsdatum"
            />
            <v-text-field
              label="Urlaubstage"
              v-model="mitarbeiter.urlaubstage"
              type="number"
            />
            <v-row>
              <v-col cols="2" class='d-flex justify-center'>
                <v-checkbox v-model="mitarbeiter.checkboxStandard_arbeitszeiten" />
              </v-col>
              <v-col>
                <v-select
                  label="Standard Lohngruppe"
                  :items="$store.state.f420.store.settings.lohngruppen"
                  item-text="name"
                  item-value="id"
                  v-model="mitarbeiter.standard_lohngruppe"
                  :disabled="!mitarbeiter.checkboxStandard_arbeitszeiten"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class='d-flex justify-center'>
              </v-col>
              <v-col>
                <v-text-field
                  label="Standard Arbeitsbeginn"
                  v-model="mitarbeiter.standard_arbeitsbeginn"
                  :disabled="!mitarbeiter.checkboxStandard_arbeitszeiten"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class='d-flex justify-center'>
              </v-col>
              <v-col>
                <v-text-field
                  label="Standard Arbeitsende"
                  v-model="mitarbeiter.standard_arbeitsende"
                  :disabled="!mitarbeiter.checkboxStandard_arbeitszeiten"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class='d-flex justify-center'>
              </v-col>
              <v-col>
                <v-text-field
                  label="Standard Pausen"
                  v-model="mitarbeiter.standard_pausen"
                  :disabled="!mitarbeiter.checkboxStandard_arbeitszeiten"
                  type="time"
                />
                <v-divider />
                <div align="center">
                  <v-dialog
                    width="500"
                    v-for="(ausnahme, idx) in mitarbeiter.ausnahmen"
                    :key="idx"
                    v-model="dialogsEditAusnahme[idx]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red lighten-2"
                        dark x-small
                        class="mx-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ wochentage.filter((o) => o.id === ausnahme.weekday)[0].name }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        Ausnahme
                      </v-card-title>

                      <v-card-text>
                        <v-select
                          v-model="ausnahme.weekday"
                          label="Wochentag"
                          :items="wochentage"
                          item-text="name"
                          item-value="id"
                        />
                        <v-select
                          v-model="ausnahme.lohngruppe"
                          label="Lohngruppe"
                          :items="$store.state.f420.store.settings.lohngruppen"
                          item-text="name"
                          item-value="id"
                        />
                        <v-text-field
                          label="Arbeitsbeginn"
                          v-model="ausnahme.arbeitsbeginn"
                          type="time"
                        />
                        <v-text-field
                          label="Arbeitsende"
                          v-model="ausnahme.arbeitsende"
                          type="time"
                        />
                        <v-text-field
                          label="Pausen"
                          v-model="ausnahme.pausen"
                          type="time"
                        />
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          text small color="error"
                          @click="delAusnahme(idx)"
                        >
                          entfernen
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          text small color="primary"
                          @click="dialogsEditAusnahme[idx] = false"
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    x-small
                    class="ma-4"
                    @click="addAusnahme();"
                    v-if="mitarbeiter.ausnahmen"
                    :disabled="
                      mitarbeiter.ausnahmen.length >= 7
                    "
                  >
                    <v-icon small>mdi-plus-box</v-icon>
                    Ausnahme
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class='d-flex justify-center'>
                <v-checkbox v-model="mitarbeiter.checkboxVertrag_festlohn" />
              </v-col>
              <v-col>
                <v-select
                  label="Vertragslohngruppe"
                  :items="$store.state.f420.store.settings.lohngruppen"
                  item-text="name"
                  item-value="id"
                  v-model="mitarbeiter.vertrag_lohngruppe"
                  :disabled="!mitarbeiter.checkboxVertrag_festlohn"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class='d-flex justify-center'>
              </v-col>
              <v-col>
                <v-text-field
                  label="Vertragsstunden"
                  v-model="mitarbeiter.vertrag_stunden"
                  :disabled="!mitarbeiter.checkboxVertrag_festlohn"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            text small
            @click="showDialog = false"
          >
            schließen
          </v-btn>
          <!-- <v-spacer></v-spacer>
          <v-btn
            color="error"
            text small
            @click="showDialog = false"
          >
            zurücksetzen
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text small
            @click="saveMitarbeiter();"
            :loading="$store.state.f420.loading.saveMitarbeiter"
            :disabled="!checkIsChanged || !formvalid"
          >
            speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '@/components/Inputs/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  props: {
    idMitarbeiter: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    formvalid: null,
    dialogsEditAusnahme: {},
    dialogDelMitarbeiter: false,
    showDialog: false,
    lade: true,
    mitarbeiter: {},
    snapshotMitarbeiter: '',
    wochentage: [
      { id: 0, name: 'Sonntags' },
      { id: 1, name: 'Montags' },
      { id: 2, name: 'Dienstags' },
      { id: 3, name: 'Mittwochs' },
      { id: 4, name: 'Donnerstags' },
      { id: 5, name: 'Freitags' },
      { id: 6, name: 'Samstags' },
    ],
  }),
  computed: {
    checkIsChanged() {
      let res = false;
      const mitarbeiter = JSON.parse(JSON.stringify(this.mitarbeiter));
      if (this.snapshotMitarbeiter !== JSON.stringify(mitarbeiter)) {
        res = true;
      }
      return res;
    },
  },
  watch: {
    showDialog(neu) {
      if (neu === false) {
        this.$router.push({ path: '/420?' });
      }
    },
    idMitarbeiter() {
      this.checkDialog();
    },
    jahr() {
      this.checkDialog();
    },
    monat() {
      this.checkDialog();
    },
  },
  methods: {
    delAusnahme(idx) {
      this.mitarbeiter.ausnahmen.splice(idx, 1);
      this.dialogsEditAusnahme[idx] = false;
    },
    addAusnahme() {
      let wochentag = 0;
      this.mitarbeiter.ausnahmen.forEach((a) => {
        if (a.weekday === wochentag) {
          wochentag += 1;
        }
      });
      this.mitarbeiter.ausnahmen.push({
        id: 0,
        weekday: wochentag,
        arbeitsbeginn: this.mitarbeiter.standard_arbeitsbeginn,
        arbeitsende: this.mitarbeiter.standard_arbeitsende,
        pausen: this.mitarbeiter.standard_pausen,
        lohngruppe: this.mitarbeiter.standard_lohngruppe,
      });
    },
    delMitarbeiter() {
      this.$store.dispatch('f420/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'delMitarbeiter',
          idDelMitarbeiter: this.mitarbeiter.id,
        },
      }).then(() => {
        let success = true;
        const m = this.$store.state.f420.store.mitarbeiter
          .filter((o) => o.id === this.mitarbeiter.id);
        if (m.length > 0) {
          success = false;
        }
        if (success) {
          this.showDialog = false;
        } else {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        }
        this.dialogDelMitarbeiter = false;
      });
    },
    saveMitarbeiter() {
      const timeLastEdit = parseInt((new Date().getTime() / 1000), 10);
      const mitarbeiter = JSON.parse(JSON.stringify(this.mitarbeiter));
      mitarbeiter.timeLastEdit = timeLastEdit;
      this.$store.dispatch('f420/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'saveMitarbeiter',
          mitarbeiter,
        },
      }).then(() => {
        let success = false;
        const m = this.$store.state.f420.store.mitarbeiter
          .filter((o) => {
            if (
              o.id === this.idMitarbeiter
              || (
                this.idMitarbeiter === 0
                && parseInt(o.personalnummer, 10) === parseInt(mitarbeiter.personalnummer, 10)
              )
            ) {
              return o;
            }
            return null;
          });
        if (m.length > 0) {
          if (m[0].timeLastEdit === mitarbeiter.timeLastEdit) {
            success = true;
            mitarbeiter.id = m[0].id;
            this.mitarbeiter.id = m[0].id;
            this.mitarbeiter.name = `${mitarbeiter.nachname}, ${mitarbeiter.vorname}`;
            this.mitarbeiter.ausnahmen = m[0].ausnahmen;
          }
        }
        if (success) {
          this.mitarbeiter.timeLastEdit = timeLastEdit;
          this.abrechnungSnapshot = JSON.stringify(this.mitarbeiter);
          if (this.idMitarbeiter === 0) {
            this.$router.push({ path: `/420?em_idm=${m[0].id}` });
            this.showDialog = false;
          } else {
            this.checkDialog();
          }
        } else {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        }
      });
    },
    checkDialog() {
      if (this.idMitarbeiter >= 0) {
        this.showDialog = true;
        const ladeIntervall = setInterval(() => {
          const m = this.$store.state.f420.store.mitarbeiter
            .filter((o) => o.id === this.idMitarbeiter);
          if (m.length > 0) {
            // Mitarbeiter gefunden
            clearInterval(ladeIntervall);
            this.startEditMitarbeiter(JSON.parse(JSON.stringify(m[0])));
          } else if (this.$store.state.f420.store.v > 0) {
            // Mitarbeiter geladen, aber kein MA mit dieser ID gefunden
            // Erstelle einen neuen Mitarbeiter
            clearInterval(ladeIntervall);
            const jetzt = new Date();
            let jetztText = `${jetzt.getFullYear()}-${String(jetzt.getMonth() + 1).padStart(2, '0')}`;
            jetztText += `-${String(jetzt.getDate()).padStart(2, '0')}`;
            this.startEditMitarbeiter({
              ausnahmen: [],
              eintrittsdatum: jetztText,
              id: 0,
              lohnabrechnungen: [],
              nachname: '',
              vorname: '',
              name: '',
              personalnummer: 0,
              standard_arbeitsbeginn: '',
              standard_arbeitsende: '',
              standard_lohngruppe: 0,
              standard_pausen: '',
              urlaubstage: 0,
              vertrag_lohngruppe: 0,
              vertrag_stunden: 0,
            });
          }
        }, 100);
      } else {
        this.showDialog = false;
      }
    },
    startEditMitarbeiter(mitarbeiter) {
      const m = mitarbeiter;
      m.personalnummer = String(m.personalnummer);
      m.vertrag_stunden = String(m.vertrag_stunden);
      m.checkboxStandard_arbeitszeiten = true;
      if (
        m.standard_lohngruppe === 0
        && this.$store.state.f420.store.settings.lohngruppen.length > 0
      ) {
        m.standard_lohngruppe = this.$store.state.f420.store.settings.lohngruppen[0].id;
      }
      if (
        m.standard_lohngruppe === 0
        || m.standard_arbeitsbeginn === ''
        || m.standard_pausen === ''
      ) {
        m.checkboxStandard_arbeitszeiten = false;
      }
      m.checkboxVertrag_festlohn = true;
      if (
        m.vertrag_lohngruppe === 0
        && this.$store.state.f420.store.settings.lohngruppen.length > 0
      ) {
        m.vertrag_lohngruppe = this.$store.state.f420.store.settings.lohngruppen[0].id;
      }
      if (
        m.vertrag_lohngruppe === 0
        || m.vertrag_stunden === '0'
      ) {
        m.checkboxVertrag_festlohn = false;
      }
      m.lohnabrechnungen = [];
      this.mitarbeiter = m;
      this.snapshotMitarbeiter = JSON.stringify(m);
    },
  },
  created() {
    this.checkDialog();
  },
};
</script>
